import React from "react";
import { Link } from "react-router-dom";

function RecipeData(props) {
    let bg_recipe_image;
    let recipe_location;
    let recipe_name;
    let recipe;
    let recipe_video;
    let tags = [];
    switch (props.page) {
        case 'chocolate':
            bg_recipe_image = 'chocolate-r-img';
            recipe_location = 'Chocolate Premix';
            recipe_name = 'Chocolate Drink';
            recipe = <><div className="p-3 bg-primary rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01. In 100 ml of boiling milk add 1-2 TSP of Ragi Drink Premix</div><div className="p-3 bg-theme  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">02. Stir well and cook on low flame for 2 - 3 min</div><div className="p-3 bg-danger  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Garnish with chocolate chips</div><div div className="p-3 bg-warning  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04. Enjoy warm</div></>;
            recipe_video = <><iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/499qpTuJmAI" title="Dr Ragi - Chocolate Drink Premix Recipe | Ragi/Nagli/Nachani/Finger Millet Drink" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></>;
            tags = ['Drink', 'Chocolate'];
            break;

        case 'elaichi':
            bg_recipe_image = 'elaichi-r-img';
            recipe_location = 'Elaichi Premix';
            recipe_name = 'Elaichi Drink';
            recipe = <><div className="p-3 bg-primary rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01. In 100 ml of boiling milk add 1-2 TSP of Ragi Drink Premix</div><div className="p-3 bg-theme  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">02. Stir well and cook on low flame for 2 - 3 min</div><div className="p-3 bg-danger  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Garnish with dry fruits</div><div div className="p-3 bg-warning  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04. Enjoy warm</div></>;
            recipe_video = <><iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/D1EDnNkiNS0" title="Dr Ragi - Dhokla Premix Recipe | Ragi/Nagli/Nachani/Finger Millet Drink" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></>;
            tags = ['Drink', 'Elaichi'];
            break;

        case 'dhokla':
            bg_recipe_image = 'dhokla-r-img';
            recipe_location = 'Dhokla Premix';
            recipe_name = 'Dhokla Premix';
            recipe = <><div className="p-3 bg-primary rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01. Add 2 TBSP of in 100 ml of water</div><div className="p-3 bg-theme  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">02. Pour the mixture in 100 grams of ragi dhokla mix</div><div className="p-3 bg-danger  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Gently mix to form smooth batterwithout lumps</div><div className="p-3 bg-warning  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04. Grease the trey with little oil, pour the batter in it evenly</div><div className="p-3 bg-success  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">05. Stream for 15-20 min or till done</div><div className="p-3 bg-info  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">06. Remove and allow to cool for 10 min and cut into small cubes</div><div className="p-3 lightbrown  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">07. Temper with mustard seeds & curry leaves </div><div div className="p-3 bg-warning  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">08.  Garnish with coriander and grated cocnut.</div></>;
            recipe_video = <><iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/Wx069TNapkw" title="Dr Ragi Dhokla Premix" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></>;
            tags = ['pancake', 'Chocolate'];
            break;

        case 'pancake':
            bg_recipe_image = 'pancake-r-img';
            recipe_location = 'Pancake Premix';
            recipe_name = 'Pancake Premix';
            recipe = <><div className="p-3 bg-primary rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01. Mix 1 / 4 cup of Ragi pancake mix with 1 / 4 cup of milk.</div><div className="p-3 bg-theme  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">Heat a pan and pour the mix into the pan, do not spread it.</div><div className="p-3 bg-success  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Drizzle ghee or butter onto it cook one side well.</div><div className="p-3 bg-danger  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04.  Flip to the other side and cook well.</div><div div className="p-3 bg-warning  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">05. Serve with honey or dates syrup.</div></>;
            recipe_video = <><iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/sUjiRL6ESZ4" title="Dr Ragi - PanCake Premix Recipe | Ragi/Nagli/Nachani/Finger Millet PanCake" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></>;
            tags = ['Soup', 'tomato'];
            break;

        case 'tomato':
            bg_recipe_image = 'tomato-r-img';
            recipe_location = 'Tomato Soup Premix';
            recipe_name = 'Tomato Soup Premix';
            recipe = <><div className="p-3 bg-primary rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01. Mix 20 grams of ragi soup premix in 200 ml of water</div><div className="p-3 bg-theme  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">02. Stir well till there are no lumps</div><div className="p-3 bg-success  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Bring to boil, stirrng continuously</div><div div className="p-3 bg-danger  rounded-pill  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04.  Simmer for 2 - 3 minutes.Serve hot</div></>;
            recipe_video = <><iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/1PSsWyTU8cE" title="Dr Ragi Tomato Premix" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></>;
            tags = ['Ragi', 'dhokla'];
            break;

        default:
            bg_recipe_image = 'url(./images/drragi-about-banner.webp);';
            recipe_location = 'home';
            recipe_name = 'home';
            recipe = 'N U L L';
            recipe_video = ' N U L L'
            tags = ['Ragi', 'Product'];
            break;
    }
    return (
        <>
            <section className="hero-slider">
                <div className="pagetitle-bg">
                    <div className={`big-banner ${bg_recipe_image}`}></div>
                </div>
                <div className="container page-title">
                    <div className="hero text-center" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="800">
                        <h2 className="hero-title bg-theme px-5 py-2 rounded-pill border-white border border-3 my-0">
                            {recipe_name}
                        </h2>
                    </div>
                </div>
                <div className="area">
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </section>
            <div className="page_breadcrumb text-center" data-aos="zoom-in-down" data-aos-easing="linear" data-aos-duration="800">
                <ul className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to="/"><i className="ri-home-4-line color-primary"></i>Home</Link>
                    </li>
                    <li className="breadcrumb-item"><span>Recipe</span></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {recipe_location}
                    </li>
                </ul>
            </div>

            <section className="section_padding">
                <h2 className="d-none">hidden element</h2>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="blog-list">
                                <div className="blog-item mt-0 blog-content">
                                    <div className="blog-image ">
                                        {recipe_video}
                                    </div>
                                    <h3 className="blog-title blog-list-title">How to make {recipe_name}</h3>
                                    <div className="text-white">
                                        {recipe}
                                    </div>
                                </div>
                                <div className="blog-bottom-info row mt-3 align-items-center">
                                    <div className="col-12 col-md-6">
                                        <div className="blog-tags d-flex gap-2">
                                            <span>Tags: </span>
                                            {tags.map((item, index) => (
                                                <div key={index} className="curser-pointer text-theme">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <ul className="d-flex p-0 mt-3 mt-md-0 justify-content-md-end justify-content-start">
                                            <li className="m-0 me-3"><a href="https://www.facebook.com/DrRagiOfficial/" target="_blank" style={{ width: 45 }} className="s-icons f-icons"><i className="ri-facebook-fill"></i></a></li>
                                            <li className="m-0 me-3"><a href="https://www.youtube.com/channel/UCOPidVpLxybZ5_D530uezQA" style={{ width: 45 }} className="s-icons f-icons" target="_blank"><i className="ri-youtube-fill"></i></a></li>
                                            <li className="m-0 me-3"><a href="https://www.instagram.com/drragiofficial/" style={{ width: 45 }} className="s-icons f-icons" target="_blank"><i className="ri-instagram-line"></i></a></li>
                                            <li className="m-0 me-3"><a href="whatsapp://send?phone=+918208636171" style={{ width: 45 }} className="s-icons f-icons" target="_blank"><i className="ri-whatsapp-line"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecipeData;