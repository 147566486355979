import React from "react";
import RecipeData from "../components/RecipeData";
import Blog from "../components/Blog";
import Subscribe from "../components/Subscribe";
import Testimonial from "../components/Testimonial";
let metaTitle = document.getElementById('metatitle');
let metaKey = document.getElementById('metakey');
let metaDesc = document.getElementById('metadesc');

let titleValue = 'Meta Title: Buy Ragi Dhokla Premix Online | Healthy and Nutritious Snack Mix';
let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
let descValue = 'Meta Description: Enjoy a healthy and nutritious snack with our Ragi Dhokla Premix. Made with high-quality ingredients, this easy-to-make mix is perfect for those looking for a healthy snack option. Buy online now!';

metaTitle.innerText = titleValue;
metaKey.setAttribute('content', keyValue);
metaDesc.setAttribute('content', descValue);
function DhoklaRecipe() {
    return (
        <>
            <div id="wrap">

                <RecipeData page="dhokla" />

                <Blog />

                <Subscribe />

                <Testimonial />
            </div>

        </>
    )
}

export default DhoklaRecipe;