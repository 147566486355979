import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Counter from "../components/Counter";
import Service from "../components/Service";
import Testimonial from "../components/Testimonial";
import Subscribe from "../components/Subscribe";
import { Modal, Button } from 'react-bootstrap';

function AboutUs() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Get Nutritious and Delicious 100% Natural Ragi Products from Dr.Ragi';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Discover the goodness of 100% natural Ragi products from Dr.Ragi. Our bio-processed gluten-free products cater to your daily nutritional needs and taste buds. We aim to be your one-stop destination for superfoods and natural wellness products in India.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        speed: 300,
        centerMode: true,
        centerPadding: '10px',
        infinite: true,
        autoplaySpeed: 1500,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1199.5,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 767.5,
                settings: {
                    centerPadding: '30px',
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div id="wrap">
                <section className="hero-slider">
                    <div className="pagetitle-bg">
                        <div className="big-banner about-bg">
                        </div>
                    </div>
                    <div className="container page-title home-about-title">

                    </div>
                </section>
                <div>
                    <div className="page_breadcrumb text-center">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <Link to="/"><i className="ri-home-4-line color-primary"></i>Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">About us</li>
                        </ul>
                    </div>
                </div>

                <section className="section_padding">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-12 col-lg-6">
                                <div className="about_image position-relative p-sm-5">
                                    <img alt="dr-ragi-banner" src="./images/about-main.jpg" className="img-fluid " data-aos="fade-down" data-aos-duration="1000" />
                                    <div className="video-play ">
                                        <a role="button" className="video_button" onClick={handleShow}>
                                            <div className="button-outer-circle has-scale-animation"></div>
                                            <div className="button-outer-circle has-scale-animation has-delay-short"></div>
                                            <i className="ri-play-line"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mt-mobile featured_blog">
                                <div className="small_heading mb-1 about-heading">
                                    <h6>Presenting unmatched research food products.</h6><span className="right_line"></span>
                                </div>
                                <h2 className="display-6 mb-30 text-theme menuh" style={{ opacity: 1, fontSize: '3rem', lineHeight: '60px', }}>"Introducing Unmatched Research-Based Food Products,
                                    Crafted with Over 6 Years of Rigorous PhD Research"</h2>
                                <p>The journey from being a Ragi Girl to Dr. Ragi is a fascinating one. Ragi is a super grain that is loaded
                                    with nutrients, and it has been an essential part of the Indian diet for centuries</p>
                                <p>r. Tahsin Kazi's passion for Ragi started during her PHD research. Dr. Kazi focused on Ragi and
                                    conducted extensive research on its nutritional properties. She learned about the various ways in which
                                    Ragi can be processed and how it can be used to create a wide range of food products. She also
                                    discovered that Ragi is an excellent source of antioxidants, which can help reduce the risk of
                                    health-related problems.
                                </p>
                                <p>She spent several years studying Ragi and exploring its potential health benefits. Her research led her to
                                    develop a range of Ragi-based products that are bio-processed and packed with nutrients.</p>
                            </div>
                            <ul className="about-service row">
                                <li className="d-flex align-items-center  col-md-6">
                                    <img alt="dr-ragi-vission" src="./images/drragi-vision.png" className="abouticons p-2 overflow-visible border-theme rounded-circle" />
                                    <div className="extra-about">
                                        <h3>Our Vision</h3>
                                        <p className="mb-0">To be a one-stop destination to buy superfoods and natural wellness
                                            products in India.</p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-center  col-md-6">
                                    <img alt="dr-ragi-mission" src="./images/drragi-mission.png" className="abouticons p-2 overflow-visible border-theme rounded-circle" />
                                    <div className="extra-about">
                                        <h3>Our Mission</h3>
                                        <p className=" mb-0">Maintaining the highest standards in sourcing, packaging and
                                            distribution of our products.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="bg-black section_padding">
                    <div className="container">
                        <div className="section_heading text-center">
                            <div className="small_heading mb-1"><span className="left_line"></span>
                                <h6>Our Research</h6><span className="right_line"></span>
                            </div>
                            <h2 className="main_heading mb-0 text-white">Research & Observation</h2>
                            <h3 className="d-none">Hidden</h3>
                        </div>
                    </div>
                    <div className="container">
                        <Slider {...settings}>
                            <div className=" mx-3 position-relative">
                                <img src="./images/pdf1.jpg" className="gal-img" />
                                <a target="_blank" href="https://www.researchgate.net/publication/318731185_Calcium_and_Iron_Rich_Recipes_of_Finger_millet" className="stretched-link"></a>
                            </div>
                            <div className=" mx-3 position-relative">
                                <img src="./images/pdf2.jpg" className="gal-img" />
                                <a target="_blank" href="https://www.foodsciencejournal.com/archives/2017/vol2/issue3/2-2-37" className="stretched-link"></a>
                            </div>
                            <div className=" mx-3 position-relative">
                                <img src="./images/pdf3.jpg" className="gal-img" />
                                <a target="_blank" href="https://updatepublishing.com/journal/index.php/jsa/article/view/4208" className="stretched-link"></a>
                            </div>
                            <div className=" mx-3 position-relative">
                                <img src="./images/pdf4.jpg" className="gal-img" />
                                <a target="_blank" href="https://afst.valahia.ro/wp-content/uploads/2023/02/III.3_Auti.pdf" className="stretched-link"></a>
                            </div>
                            <div className=" mx-3 position-relative">
                                <img src="./images/pdf5.jpg" className="gal-img" />
                                <a target="_blank" href="https://www.sciencegate.app/keyword/221678" className="stretched-link"></a>
                            </div>
                        </Slider>
                    </div>
                </section>

                <Counter color='#5571f2'></Counter>

                <Service></Service>

                <Testimonial></Testimonial>

                <Subscribe></Subscribe>

            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body><iframe width="885" height="498" src="https://www.youtube.com/embed/F6hxSmmggwE" title="Dr Ragi - Chocolate Drink Premix Recipe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></Modal.Body>
            </Modal>
        </>
    )
}

export default AboutUs;