import RecipeData from "../components/RecipeData";
import Blog from "../components/Blog";
import Subscribe from "../components/Subscribe";
import Testimonial from "../components/Testimonial";
function PankcakeRecipe() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Whip Up Delicious Pancakes in Minutes with Our Instant Pancake Premix';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Make breakfast a breeze with our Instant Pancake Premix. Our pancake mix is easy to prepare and makes fluffy, mouth-watering pancakes in minutes. Order now and enjoy a delicious breakfast any day of the week.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);

    return (
        <>
            <div id="wrap">

                <RecipeData page="pancake" />

                <Blog />

                <Subscribe />

                <Testimonial />
            </div>

        </>
    )
}

export default PankcakeRecipe;