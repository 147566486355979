import React from "react";
function Refund() {

    return (
        <>
            <section className="section_padding">
                <div className="container">
                    <p className="display-5 text-theme">Refund Policy</p>
                    <p className="display-6">No return no refund policies on Ragi products</p>
                    <p className="fs-5 lh-base">unraveling No Return, No Refund Policies on Ragi Products</p>
                    <p className="fs-5 lh-base">We implement no return no refund policies for several reasons. Some common reasons include the perishable nature of the product, hygiene concerns, or the potential for abuse or misuse of the product.Our aim of no return no refund policies is  to prevent abuse, protect from fraudulent claims, and maintain product integrity. Under this policy, customers are responsible for making informed decisions before purchasing a product. They need to carefully consider their requirements, conduct research, and ensure the item meets their needs before making a commitment.Our policies are transparent, easy to understand, and readily accessible to customers. We provide clear information regarding the policy's terms, including any time limitations, conditions, or additional fees that may apply.</p>
                    <br />
                    <p className="fs-5 lh-base"> No Return, No Refund Policy can help reduce fraud and chargebacks</p>
                </div>

            </section>
        </>

    )
}

export default Refund;