import React from "react";
function Shipping() {

    return (
        <>
            <section className="section_padding shipping">
                <div className="container">
                    <p className="display-5 text-theme">SHIPPING AND DELIVERY POLICY</p>
                    <p className="fs-5 lh-base">This Site is owned and operated by T K Foods, Limited Liability Partnership registered under the under the Limited Liability Partnership Act, 2008 and having its principal place of plot #148, D501 Swapnapoorti Tilaknagar East Mumbai 400089 (hereinafter referred to as "T K Foods”, "Site Owner", “we", "Our", or “us”).</p>
                    <ol start='1'>
                        <li className="my-3">
                            We only deliver products in cities listed on website www.drragi.com.
                        </li>
                        <li className="my-3">
                            Delivery dates, times and delivery call-off times shall be detailed on the Website when you order products.
                        </li>
                        <li className="my-3">
                            We do not guarantee any timed delivery, our regular delivery timings are between 9:00 am to 9:00 pm. Clients can request specific timings in the Special Instructions section of payment form, we will try our best to honour the client's request but cannot commit 100% efficiency in the timing of delivery. Delivery timings if promised cannot be guaranteed in case of special circumstances like extreme weather conditions, riots, strikes, elections, bandhs, during rush hour days like Valentine's Day, Mothers Day, Rakhi, New year Eve etc.
                        </li>
                        <li className="my-3">
                            Whilst we agree to use all reasonable endeavours to ensure that delivery will be on the requested delivery date or during the delivery period
                        </li>
                        <li className="my-3">
                            We are not liable for the condition of products that reach the customer through third party logistics/ apps or any other type of pick up arranged by the customer.
                        </li>
                        <li className="my-3">
                            We will deliver the products ordered by you to the address you give to us for delivery at the time you make your order. We cannot guarantee when any item will be dispatched, and we are unable to specify a time for delivery.
                        </li>
                        <li className="my-3">
                            We are committed to delivering all our orders within the given time slot, on the given day. If by any chance an order is delivered outside of the committed time slot, it is not intentional and you can refuse to accept the delivery and would be liable for refund.
                        </li>
                        <li className="my-3">
                            Some orders will require a signature on delivery. This will apply in the case of delivery of certain products or to certain locations where a third party is involved, such as offices, hospitals, airports, hotels, ships and other business locations. The signature of any person authorised to accept delivery on behalf of the organisation shall be accepted as proof of delivery to your chosen recipient.
                        </li>
                        <li className="my-3">
                            You will become the owner of the products you have ordered when they have been delivered to you or the representative of the recipient. You are responsible for any insurance (where relevant) security after delivery. Once products have been delivered to you, they will be held at your own risk and we shall not be liable for their loss or destruction.
                        </li>
                        <li className="my-3">
                            If you happen to be unavailable when the delivery executive reaches your location, he will hand the order to your building’s security guard. Re-delivering our products is not possible.
                        </li>
                        <li className="my-3">
                            In case the final customer is not available for receiving the placed order and we have strict instructions to deliver only to the intended customer the order shall be considered delivered and no refund will be made. Please refer Refunds, Cancellation and Modification Policy for further details.
                        </li>
                        <li className="my-3">
                            Neither we, nor any courier or postal service that we use shall be liable for any failure to perform, where such failure or delay results from any circumstances outside our reasonable control including but not limited to any fire, flood, pandemic, explosion, accident, adverse weather conditions, traffic congestion, mechanical breakdown, obstruction of any private or public highway, riot, government act, act of war, terrorism, act of God, or from any industrial dispute or strike whatsoever.
                        </li>
                        <li className="my-3">
                            Please ensure that you enter full and accurate details, including the address and the postal code, of the recipient. We will not be responsible for any orders which are not delivered due to incorrect or incomplete information being supplied by you.
                        </li>
                        <li className="my-3">
                            Any changes to the recipient address would be entertained free of cost if they are requested before 48 hours of the actual delivery. Changes after that will not be entertained and order would not be cancelled.
                        </li>
                        <li className="my-3">
                            Change of address would not be possible for products after dispatch.
                        </li>
                        <li className="my-3">
                            Delivery charges depend upon delivery areas & minimum order amount.
                        </li>
                        <li className="my-3">
                            Minimum order amount to process your order, minimum order amount for free delivery & delivery charges are subject to change.
                        </li>
                        <li className="my-3">
                            We may restrict orders and/or deliveries to certain areas and/or dates.
                        </li>
                        <li className="my-3">
                            We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person or per order.
                        </li>
                        <li className="my-3">
                            If we find that the same order has been made twice we will try to contact you and get that clarified. If you are not contactable we will take the decision on your behalf and that will be final.
                        </li>
                    </ol>
                </div>

            </section >
        </>

    )
}

export default Shipping;