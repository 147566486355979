import React, { useEffect } from "react";
import $ from "jquery";
function Counter(props) {
    let bgcolor;
    if (props === 'product') {
        bgcolor = '#ffe4e1;';
    }
    const bgstyle = {
        background: bgcolor
    };
    useEffect(() => {
        $(".testimonial .indicators li").click(function () {
            var i = $(this).index();
            var targetElement = $(".testimonial .tabs li");
            targetElement.eq(i).addClass('active');
            targetElement.not(targetElement[i]).removeClass('active');
        });

        $(".testimonial .tabs li").click(function () {
            var targetElement = $(".testimonial .tabs li");
            targetElement.addClass('active');
            targetElement.not($(this)).removeClass('active');
        });
    }, []);
    return (
        <section className={`py-5 px-sm-5 overflow-hidden testi position-relative ${props === 'dhokla' ? ' d-none' : ''}`}
            style={bgstyle}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#b04943" fill-opacity="1" d="M0,160L60,165.3C120,171,240,181,360,186.7C480,192,600,192,720,181.3C840,171,960,149,1080,170.7C1200,192,1320,256,1380,288L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>

            <div>
                <div className="section_heading text-center mb-0">
                    <div className="small_heading mb-1"><span className="left_line"></span>
                        <h6>Happy Customers</h6><span className="right_line"></span>
                    </div>
                    <h2 className="main_heading mb-30">What Our Customers Say</h2>
                    <p>We pride ourselves on providing high-quality products. Here are some testimonials from our satisfied customers.</p>
                </div>
                <section className="testimonial">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-block ">
                                <ol className="carousel-indicators tabs elcheck">
                                    <li data-bs-target="#carouselExampleIndicators" className="tab-img active" data-bs-slide-to="0">
                                        <div className="text-center" data-aos="fade-up" data-aos-duration="500">
                                            <img alt="dr-ragi-placeholder" width="160px" height="160px" className="object-cover t rounded-circle" src="./images/drragi-placeholdermen.webp" />
                                        </div>
                                    </li>
                                    <li data-bs-target="#carouselExampleIndicators" className="tab-img" data-bs-slide-to="1">
                                        <div className="text-center" data-aos="fade-up" data-aos-duration="1000">
                                            <img alt="dr-ragi-placeholder" width="160px" height="160px" className="object-cover t rounded-circle" src="./images/drragi-placeholderwomen.webp" />
                                        </div>
                                    </li>
                                    <li data-bs-target="#carouselExampleIndicators" className="tab-img" data-bs-slide-to="2">
                                        <div className="text-center" data-aos="fade-up" data-aos-duration="1000">
                                            <img alt="dr-ragi-placeholder" width="160px" height="160px" className="object-cover t rounded-circle" src="./images/drragi-placeholdermen.webp" />
                                        </div>
                                    </li>
                                    <li data-bs-target="#carouselExampleIndicators" className="tab-img" data-bs-slide-to="3">
                                        <div className="text-center" data-aos="fade-up" data-aos-duration="1200">
                                            <img alt="dr-ragi-placeholder" width="160px" height="160px" className="object-cover t rounded-circle" src="./images/drragi-placeholderwomen.webp" />
                                        </div>
                                    </li>
                                    <li data-bs-target="#carouselExampleIndicators" className="tab-img" data-bs-slide-to="4">
                                        <div className="text-center" data-aos="fade-up" data-aos-duration="1400">
                                            <img alt="dr-ragi-placeholder" width="160px" height="160px" className="object-cover t rounded-circle" src="./images/drragi-placeholdermen.webp" />
                                        </div>
                                    </li>
                                    <li data-bs-target="#carouselExampleIndicators" className="tab-img" data-bs-slide-to="5">
                                        <div className="text-center" data-aos="fade-up" data-aos-duration="1400">
                                            <img alt="dr-ragi-placeholder" width="160px" height="160px" className="object-cover t rounded-circle" src="./images/drragi-placeholderwomen.webp" />
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center align-items-center" data-aos="fade-left" data-aos-offset="300" data-aos-duration="1000">
                                <div id="carouselExampleIndicators" data-bs-interval="false" className="carousel slide" data-bs-ride="carousel">
                                    <h3>WHAT OUR CLIENTS SAY</h3>
                                    <h2>TESTIMONIALS</h2>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="quote-wrapper">
                                                <p>I've been a fan of Dr. Ragi's products for years now and continue to be impressed by their commitment to quality and innovation. Their range of gluten-free and organic food products has made it easier for me to maintain a healthy and balanced diet, without compromising on taste.</p>
                                                <h3>Mrs. Shubadha Rathi
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="quote-wrapper">
                                                <p>As a new mom, I've been looking for healthy and nutritious snacks to keep me fueled throughout the day. Dr. Ragi's products have been the perfect solution—they're easy to make, taste great, and provide me with the energy I need to take care of my little one.</p>
                                                <h3>Mrs. Saniya Shaikh
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="quote-wrapper">
                                                <p>As someone who struggles with gluten intolerance, I am always on the lookout for gluten-free alternatives. Dr Ragi's food products have been a godsend for me. </p>
                                                <h3>Mrs.Shabeena Shaikh</h3>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="quote-wrapper">
                                                <p>जब से मैंने रागी चॉकलेट हेल्थ ड्रिंक का सेवन करना शुरू किया है, मैंने अपने समग्र स्वास्थ्य में महत्वपूर्ण सुधार महसूस किया है। यह मेरे शरीर को मजबूत करता है और बेहतर पाचन में मदद करता है" </p>
                                                <h3>Prof. Sanjay Auti
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="quote-wrapper">
                                                <p>मैंने हाल ही में रागी ढोकला, प्रीमिक्स (संस्थापक डॉ. तहसीन काज़ी) की कोशिश की और मुझे कहना होगा, यह पारंपरिक ढोकला के लिए एक सुखद मोड़ है। रागी के आटे और मसालों का मेल इसे एक अनोखा और स्वादिष्ट स्वाद देता है। यह अब मेरे पसंदीदा स्वस्थ स्नैक्स में से एक है </p>
                                                <h3>Mr.Bhas Bhamre
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="quote-wrapper">
                                                <p>स्वास्थ्य के प्रति जागरूक व्यक्ति के रूप में, रागी ढोकला प्रीमिक्स मेरा पसंदीदा नाश्ता बन गया है। यह प्राकृतिक अवयवों से बना है, परिरक्षकों से मुक्त है, और प्रोटीन और फाइबर की अच्छी खुराक प्रदान करता है। यह मेरे स्वास्थ्य लक्ष्यों से समझौता किए बिना मेरी लालसा को संतुष्ट करता है। ऐसी अद्भुत खोज के लिए डॉ.तहसीन काजी को धन्यवाद।</p>
                                                <h3>Mrs Raheela Shaikh
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <ol className="carousel-indicators indicators">
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"></li>
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"></li>
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"></li>
                                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5"></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}

export default Counter;