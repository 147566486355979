import React from "react";
function ContactUs() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Get Nutritious and Delicious 100% Natural Ragi Products from Dr.Ragi';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Discover the goodness of 100% natural Ragi products from Dr.Ragi. Our bio-processed gluten-free products cater to your daily nutritional needs and taste buds. We aim to be your one-stop destination for superfoods and natural wellness products in India.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);
    return (
        <>
            <div id="wrap">
                <section className="hero-slider d-none d-xl-block">
                    <div className="pagetitle-bg">
                        <div className="big-banner contact-bg"></div>
                    </div>

                    <div className="container page-title">
                    </div>
                </section>
                <h3 className="d-none">Hidden</h3>
                <h4 className="d-none">Hidden</h4>
                <div className="page_breadcrumb text-center" data-aos="zoom-in-down" data-aos-easing="linear" data-aos-duration="800">
                    <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><a href="home-1.html"><i className="ri-home-4-line color-primary"></i> Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                    </ul>
                </div>

                <section className="section_padding">
                    <h5 className="d-none">Hidden</h5>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="contact-us book-now bg-light" data-aos="fade-right" data-aos-duration="1200">
                                    <div className="section_heading">
                                        <div className="small_heading mb-3">
                                            <h6>Contact Us</h6><span className="right_line"></span>
                                        </div>
                                        <h2 className="form-title">Send Us A Message</h2>
                                        <p className="text-black">Your email address will not be published*</p>
                                    </div>
                                    <form className="row align-items-center">
                                        <div className="col-12">
                                            <div className="input-group custom-field">
                                                <div className="custom-field-icon"><i className="ri-user-line"></i></div>
                                                <input type="text" className="custom-form-control" placeholder="Username" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group custom-field">
                                                <div className="custom-field-icon"><i className="ri-phone-line"></i></div>
                                                <input type="number" className="custom-form-control" placeholder="Phone" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group custom-field">
                                                <div className="custom-field-icon"><i className="ri-mail-line"></i></div>
                                                <input type="email" className="custom-form-control" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group custom-field">
                                                <textarea className="custom-form-control" rows="4" cols="100" placeholder="Special Message Write Here"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 book-now-btn">
                                            <button type="submit" className="book-btn bg-hover-black">Send Message</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="contact-infromation">
                                    <h2>We are here for help you! Arrange a meeting.</h2>
                                    <p className="mb-0">We'd love to hear from you! If you have any questions, suggestions, or just want to say
                                        hello, feel free to reach out to us using the contact information below</p>
                                    <div className="text-dark">
                                        <ul className="mt-4 row g-3">
                                            <div className=" col-md-6">
                                                <li className="m-0 d-flex f-icons rounded-pill align-items-center px-4 mt-2 position-relative"><a href="https://www.facebook.com/DrRagiOfficial/" target="_blank" className="s-icons f-icons stretched-link">
                                                    <i className="ri-facebook-fill"></i>
                                                </a>
                                                    <a href="" className="ms-3 text-white">DrRagiOfficial</a>
                                                </li>
                                            </div>
                                            <div className=" col-md-6">
                                                <li className="m-0 d-flex f-icons rounded-pill align-items-center px-4 mt-2 position-relative"><a href="whatsapp://send?phone=+918208636171" className="s-icons f-icons stretched-link" target="_blank">
                                                    <i className="ri-whatsapp-line"></i>
                                                </a>
                                                    <a href="" className="ms-3 text-white">Whatsapp</a>
                                                </li>
                                            </div>
                                            <div className=" col-md-6">
                                                <li className="m-0 d-flex f-icons rounded-pill align-items-center px-4 mt-2 position-relative"><a href="https://www.instagram.com/drragiofficial/" className="s-icons f-icons stretched-link" target="_blank">
                                                    <i className="ri-instagram-line"></i>
                                                </a>
                                                    <a href="" className="ms-3 text-white">@drragiofficial</a>
                                                </li>
                                            </div>
                                            <div className=" col-md-6">
                                                <li className="m-0 d-flex f-icons rounded-pill align-items-center px-4 mt-2 position-relative"><a href="mailto:hello@drragi.in" className="s-icons f-icons stretched-link" target="_blank">
                                                    <i className="ri-mail-fill"></i>
                                                </a>
                                                    <a href="" className="ms-3 text-white">hello@drragi.in</a>
                                                </li>
                                            </div>
                                            <div className=" col-md-6">
                                                <li className="m-0 d-flex f-icons rounded-pill align-items-center px-4 mt-2 position-relative"><a href="https://twitter.com/DrRagiOfficial" className="s-icons f-icons stretched-link" target="_blank">
                                                    <i className="ri-twitter-line"></i>
                                                </a>
                                                    <a href="" className="ms-3 text-white">DrRagiOfficial</a>
                                                </li>
                                            </div>
                                            <div className=" col-md-6">
                                                <li className="m-0 d-flex f-icons rounded-pill align-items-center px-4 mt-2 position-relative"><a href="https://www.youtube.com/channel/UCOPidVpLxybZ5_D530uezQA" className="s-icons f-icons stretched-link" target="_blank">
                                                    <i className="ri-youtube-fill"></i>
                                                </a>
                                                    <a href="" className="ms-3 text-white">Dr Ragi</a>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>

    )
}

export default ContactUs;