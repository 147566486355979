import React from "react";
import ProductHeroSection from "../components/ProductHeroSection";
import FactsProduct from "../components/FactsProduct";
import Testimonial from "../components/Testimonial";
import RelatedProduct from "../components/RelatedProduct";
function MaltProduct() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Meta Title: Buy Ragi Malt Premix Online | Nutritious and Delicious Drink Mix';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Meta Description: Looking for a healthy and delicious drink? Try our Ragi Malt Premix made with the goodness of ragi and other high-quality ingredients. This nutritious drink mix is perfect for a quick and easy breakfast or snack. Buy online now!';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);

    return (
        <>
            <ProductHeroSection productPage="malt" />

            <section className="section_padding overflow-hidden bg-success">
                <div className="container">
                    <div className="row position-relative z-1 align-items-md-center align-items-lg-start">
                        <div className="position-absolute d-none d-lg-block top-0 z--1">
                            <img alt="dr-ragi-glow" src="./images/drragi-glow.webp" className="img-fluid mt-n1 rotate-circle" />
                        </div>
                        <div className="col-md-12 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <h2 className="display-5 h-w-font">Malt Premix</h2>
                                <p>"Experience the incredible versatility of our Ragi Malt – a delicious, healthy, and
                                    oh-so-tasty choice! This wholesome delight serves as a multipurpose wonder, perfect
                                    for any time of the day. Whether as a nourishing breakfast option or a delightful snack,
                                    our Ragi Malt will surely satisfy your cravings while providing you with a burst of health
                                    benefits. Treat yourself to the goodness of Ragi, and embrace a journey of taste and
                                    well-being!"                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center">
                                <img alt="dr-ragi-malt-premix" src="./images/drragi-malt.webp" data-aos="fade-up" className="w-90 proimg" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <p className="text-white f-40">Get Nitritious Products at Home</p>
                                <p className="mt-4">Ratings : <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></p>
                                <h4 className="h-w-font my-3 nowrap d-flex">Social Media: <a href="https://www.facebook.com/DrRagiOfficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-facebook mx-2 f-30"></i></a><a href="https://www.youtube.com/channel/UCOPidVpLxybZ5_D530uezQA" target="_blank" className="text-white"> <i className="fa-brands fa-square-youtube mx-2 f-30"></i></a> <a href="https://www.instagram.com/drragiofficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-instagram mx-2 f-30"></i></a> </h4>
                                <div className="mt-5">
                                    <a target="_blank" href="https://www.amazon.in/l/27943762031?ie=UTF8&marketplaceID=A21TJRUUN4KGV&me=A235IMB28GQ4TO">
                                        <button className="buybtn w-100">Buy Now</button>
                                    </a>
                                </div>
                                <div className="d-flex gap-3 justify-content-center mt-5 flex-wrap" data-aos="zoom-in-right">
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-m-icon1.webp" width="80px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-m-icon2.webp" width="80px" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FactsProduct productPage="malt" />
            <section id="howcook" className="py-5 position-relative">
                <div className="container">
                    <div className="row">
                        <h2 className="text-center mb-5 aos-init aos-animate font-monospace" data-aos="fade-up">How to Cook</h2>
                        <div className="col-lg-6 text-white">
                            <div className="p-3 bg-warning rounded fw-bold my-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                01. Mix 2 teaspoon of ragi malt in some amount of milk</div>
                            <div className="p-3 bg-pancake rounded fw-bold my-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                02. Boil water in a pan and add this mixture in it</div>
                            <div className="p-3 bg-elaichi rounded fw-bold my-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                03. Continue cooking for another 2 to 3 minutes until the mixture becomes frothy</div>
                            <div className="p-3 bg-primary rounded fw-bold my-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                04. Let the ragi mixture cool</div>
                            <div className="p-3 bg-theme rounded fw-bold my-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                02. Next, in a glass, add the buttermilk along with salt and then add the cooled ragi to this, Mix well</div>
                            <div className="p-3 bgragi rounded fw-bold my-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                03. Garnish with coriander leaves and serve</div>
                        </div>
                        <div className="col-lg-6 text-white">
                            <div className="p-3 bg-primary rounded fw-bold my-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                01. Mix 2 teaspoon of ragi malt in some amount of milk</div>
                            <div className="p-3 bg-theme rounded fw-bold my-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">02.
                                02. Add it to 100 ml of milk and boil with continuous stirring</div>
                            <div className="p-3 bg-success rounded fw-bold my-2 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                03. Add sweeter of your choice</div>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonial />
            <RelatedProduct />

        </>
    )
}

export default MaltProduct;