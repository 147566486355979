import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function RelatedProduct(props) {
    const navigate = useNavigate();

    function dhoklalink() {
        navigate("/dr-ragi-dhokla-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function pancakelink() {
        navigate("/dr-ragi-pancake-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function chocolatelink() {
        navigate("/dr-ragi-chocolate-drink-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function maltlink() {
        navigate("/dr-ragi-malt-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function tomatolink() {
        navigate("/dr-ragi-tomato-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function elaichilink() {
        navigate("/dr-ragi-elaichi-drink-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        speed: 300,
        centerPadding: '20px',
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true
    }
    return (
        <>
            <section className="position-relative bg-theme">

                <div className="section_padding pt-1 position-relative d-none d-lg-block ">
                    <h2 className=" text-white menuh mb-4 text-center">More Products</h2>
                    <div className="container pt-5 d-flex justify-content-center">
                        <div to="dr-ragi-dhokla-premix" onClick={dhoklalink} className={`relcard brown ${props.page == 'dhokla' ? ' d-none' : ''}`}>
                            <img alt="dr-ragi-dhokla-premix" src="./images/drragi-dhokla.webp" className="img-fluid mx-auto" data-aos="flip-right" data-aos-duration="1000" />
                        </div>
                        <div to="dr-ragi-pancake-premix" onClick={pancakelink} className={`relcard bg-warning ${props.page == 'pancake' ? ' d-none' : ''}`}>
                            <img alt="dr-ragi-pancake-premix" src="./images/drragi-pancake.webp" className="img-fluid mx-auto" data-aos="flip-right" data-aos-duration="1000" />
                        </div>
                        <div to="dr-ragi-elaichi-drink-premix" onClick={elaichilink} className={`relcard bg-elaichi ${props == 'elaichi' ? ' d-none' : ''}`}>
                            <img alt="dr-ragi-elaichi-premix" src="./images/drragi-elaichi.webp" className="img-fluid mx-auto" data-aos="flip-right" data-aos-duration="1500" />
                        </div>
                        <div to="dr-ragi-chocolate-drink-premix" onClick={chocolatelink} className={`relcard lightbrown ${props == 'chocolate' ? ' d-none' : ''}`}>
                            <img alt="dr-ragi-chocolate-premix" src="./images/drragi-chocolate.webp" className="img-fluid mx-auto" data-aos="flip-right" data-aos-duration="1500" />
                        </div>
                        <div to="dr-ragi-malt-drink-premix" onClick={maltlink} className={`relcard bg-success ${props == 'malt' ? ' d-none' : ''}`} >
                            <img alt="dr-ragi-malt-premix" src="./images/drragi-malt.webp" className="img-fluid mx-auto" data-aos="flip-right" data-aos-duration="1500" />
                        </div>
                        <div to="dr-ragi-tomato-drink-premix" onClick={tomatolink} className={`relcard bg-danger ${props == 'tomato' ? ' d-none' : ''}`} >
                            <img alt="dr-ragi-tomato-premix" src="./images/drragi-tomato.webp" className="img-fluid mx-auto" data-aos="flip-right" data-aos-duration="2000" />
                        </div>
                    </div >
                </div >
            </section >
            <section className="section_padding bg-theme d-block d-lg-none">
                <div className="container">
                    <h2 className=" text-white menuh mb-4 text-center">More Products</h2>

                    <Slider {...settings}>
                        <div to="dr-ragi-dhokla-premix" onClick={dhoklalink} className={`${props.page == 'dhokla' ? ' d-none' : ''}`}>
                            <img alt="dr-ragi-dhokla-premix" src="./images/drragi-dhokla.webp" className="w-50 mx-auto" />
                        </div>
                        <div to="dr-ragi-pancake-premix" onClick={pancakelink} className={`${props.page == 'pancake' ? ' d-none' : ''}`}>
                            <img alt="dr-ragi-pancake-premix" src="./images/drragi-pancake.webp" className="w-50 mx-auto" />
                        </div>
                        <div to="dr-ragi-elaichi-drink-premix" onClick={elaichilink} className={`${props.page == 'elaichi' ? ' d-none' : ''}`}>
                            <img alt="dr-ragi-elaichi-premix" src="./images/drragi-elaichi.webp" className="w-50 mx-auto" />
                        </div>
                        <div to="dr-ragi-chocolate-drink-premix" onClick={chocolatelink} className={`${props.page == 'chocolate' ? ' d-none' : ''}`} >
                            <img alt="dr-ragi-chocolate-premix" src="./images/drragi-chocolate.webp" className="w-50 mx-auto" />
                        </div>
                        <div to="dr-ragi-malt-drink-premix" onClick={maltlink} className={`${props.page == 'malt' ? ' d-none' : ''}`} >
                            <img alt="dr-ragi-malt-premix" src="./images/drragi-malt.webp" className="w-50 mx-auto" />
                        </div>
                        <div to="dr-ragi-tomato-drink-premix" onClick={tomatolink} className={`${props.page == 'tomato' ? ' d-none' : ''}`} >
                            <img alt="dr-ragi-tomato-premix" src="./images/drragi-tomato.webp" className="w-50 mx-auto" />
                        </div>
                    </Slider>
                </div >
            </section >
        </>


    )
}

export default RelatedProduct;