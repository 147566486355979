import React from "react";
function Cookies() {

    return (
        <>
            <section className="section_padding">
                <div className="container">
                    <p className="display-5 text-theme">Cookies Policy</p>
                    <p className="display-6">Our Cookies Policy was last updated on August 18, 2022.</p>
                    <p className="fs-5 lh-base">This Site is owned and operated by T K Foods, a Limited Liability Partnership registered under the under the Limited Liability Partnership Act, 2008 and having its principal place of business at Plot #148, D501 Swapnapoorti Tilaknagar East Mumbai 400089(hereinafter referred to as "T K Foods”, "Site Owner", “we", "Our", or “us”).</p>
                    <br />
                    <p className="fs-5 lh-base">This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can understand what type of cookies We use, or the information We collect using Cookies and how that information is used.</p>
                    <br />
                    <p>Like most businesses, we employ “cookies” or similar technologies. This Cookie Policy tells you about the use of Cookies and similar technologies on the websites. When you first access T K Foods Foods Website, you will receive a message advising you that cookies and similar technologies are in use. By closing the message or continuing to browse the T K Foods Foods Website, you signify that you understand and agree to the use of these technologies, as described in this Cookie Policy. You do not have to accept cookies and consent can be withdrawn at any time. You can change your browser settings to refuse or restrict cookies. You may delete them after they have been placed on your device at any time. If you do not accept or delete our cookies, some areas of the T K Foods Foods website that you access may take more time to work or may not function properly.</p>
                    <br />
                    <p>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.</p>
                    <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.</p>
                </div>

            </section>
        </>

    )
}

export default Cookies;