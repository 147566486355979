import React from "react";
import ProductHeroSection from "../components/ProductHeroSection";
import FactsProduct from "../components/FactsProduct";
import HowToCook from "../components/HowToCook";
import Testimonial from "../components/Testimonial";
import RelatedProduct from "../components/RelatedProduct";
function ElaichiProduct() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Experience the Aromatic Flavor of Ellaichi with Our Instant Drink Premix';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'No data Given';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);
    return (
        <>
            <ProductHeroSection productPage="elaichi" />

            <section className="section_padding overflow-hidden bg-elaichi">
                <div className="container">
                    <div className="row position-relative z-1 align-items-md-center align-items-lg-start">
                        <div className="position-absolute d-none d-lg-block top-0 z--1">
                            <img alt="dr-ragi-glow" src="./images/drragi-glow.webp" className="img-fluid mt-n1 rotate-circle" />
                        </div>
                        <div className="col-md-12 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <h2 className="display-5 h-w-font">Elaichi Premix</h2>
                                <p>"Experience the ultimate goodness with our delightful Ragi drink premix in
                                    the rich and aromatic elaichi flavor, specially crafted to promote strong
                                    and healthy bones for our beloved seniors. Embrace the natural
                                    nourishment and relish the taste of well-being with every sip!"</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center">
                                <img alt="dr-ragi-elaichi-premix" src="./images/drragi-elaichi.webp" data-aos="fade-up" className="w-90 proimg" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <p className="text-white f-40">Get Nitritious Products at Home</p>
                                <p className="mt-4">Ratings : <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></p>
                                <h4 className="h-w-font my-3 nowrap d-flex">Social Media: <a href="https://www.facebook.com/DrRagiOfficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-facebook mx-2 f-30"></i></a><a href="https://www.youtube.com/channel/UCOPidVpLxybZ5_D530uezQA" target="_blank" className="text-white"> <i className="fa-brands fa-square-youtube mx-2 f-30"></i></a> <a href="https://www.instagram.com/drragiofficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-instagram mx-2 f-30"></i></a> </h4>
                                <div className="mt-5">
                                    <a href="https://www.amazon.in/dp/B0CCC3NFGQ?&_encoding=UTF8&tag=drragi-21&linkCode=ur2&linkId=8ff3df5c026389bff1394ff29554cc9a&camp=3638&creative=24630">
                                        <button className="buybtn w-100">Buy Now</button>
                                    </a>
                                </div>
                                <div className="d-flex gap-3 justify-content-center mt-5 flex-wrap" data-aos="zoom-in-right">
                                    <div><img alt="dr-ragi-icon" src="./images/e1.webp" width="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/e2.webp" width="120px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/e3.webp" height="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/e4.webp" width="70px" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FactsProduct productPage="elaichi" />
            <HowToCook page="elaichi" />
            <Testimonial />
            <RelatedProduct />

        </>
    )
}

export default ElaichiProduct;