import React from "react";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

function FactsProduct(props) {
    let productimg;
    let nutribox;
    let ingridbox;
    useEffect(() => {
        AOS.init({
            // once: true,
            disable: function () {
                var maxWidth = 992;
                return window.innerWidth < maxWidth;
            }
        });
        // AOS.refresh();

        const head = document.querySelector('header');
        const fact1 = document.getElementById('facts-box1');
        const fact2 = document.getElementById('facts-box2');
        const nutricious = document.getElementById('nutrival');
        const ingrident = document.getElementById('ingridval');
        const bg = document.getElementById('productsect');
        const productheading = document.getElementById('productheading');
        const productheading2 = document.getElementById('productheading2');
        bg.style.transition = '0.5s';
        const fend = 3000;
        const inend = 4000;

        function handleScroll() {
            if (document.body.offsetWidth > 992.5) {
                if (window.scrollY > fend) {
                    bg.classList.add('bg-pro');
                    fact1.classList.add('animate__fadeOutUp');
                    fact2.classList.add('animate__fadeOutUp');
                    fact2.classList.remove('animate__fadeInDown');
                    fact1.classList.remove('animate__fadeInDown');
                    nutricious.classList.add('animate__fadeInUp');
                    ingrident.classList.add('animate__fadeInUp');
                    nutricious.classList.remove('animate__fadeOutDown');
                    ingrident.classList.remove('animate__fadeOutDown');
                    productheading.style.opacity = '0';
                } else {
                    bg.classList.add('bg-pro');
                    fact2.classList.add('animate__fadeInDown');
                    fact1.classList.add('animate__fadeInDown');
                    fact2.classList.remove('animate__fadeOutUp');
                    fact1.classList.remove('animate__fadeOutUp');
                    nutricious.classList.add('animate__fadeOutDown');
                    ingrident.classList.add('animate__fadeOutDown');
                    nutricious.classList.remove('animate__fadeInUp');
                    ingrident.classList.remove('animate__fadeInUp');
                    productheading.style.opacity = '1';
                }
                if (window.scrollY < fend) {
                    productheading2.style.visibility = 'hidden';
                } else {
                    productheading2.style.visibility = 'visible';
                }

                if (window.scrollY > inend) {
                    productheading2.classList.remove('animate__fadeInDown');
                    productheading2.classList.add('animate__fadeOutUp');
                } else {
                    productheading2.classList.add('animate__fadeInDown');
                    productheading2.classList.remove('animate__fadeOutUp');
                }
            }
        }

        if (document.body.offsetWidth > 992.5) {
            window.addEventListener('scroll', handleScroll);
        };
    }, []);
    switch (props.productPage) {
        case 'chocolate':
            productimg = './images/drragi-chocolate.webp';
            nutribox = <div className="nutribox">< div className="py-2" ><div className="d-flex justify-content-between px-4 pt-3"><div>CARBOHYDRATE</div><div>84.8 GM</div></div><div className="d-flex justify-content-between px-4 "><div>SUGAR</div><div>41.1 GM</div></div><div className="d-flex justify-content-between px-4 "><div>PROTEIN</div><div>7.10 GM</div></div><div className="d-flex justify-content-between px-4 "><div>FATS</div><div>0.93 GM</div></div></div ><div className="d-flex bg-success bround text-white justify-content-between px-4 py-3"><div>ENERGY VALUE</div><div>376 KCAL</div></div></div >;
            ingridbox = <div className="d-flex flex-wrap nowrap">< div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw" ><h4 className="h-w-font m-0">Ragi</h4></div ><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Milk Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Amaranth</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Cocoa Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Rolled Oats</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Shatavari Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Sugar Barley</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Ashwagandha Powder</h4></div></div >;
            break;

        case 'elaichi':
            productimg = './images/drragi-elaichi.webp';
            nutribox = <div className="nutribox">< div className="py-2" ><div className="d-flex justify-content-between px-4 pt-3"><div>CARBOHYDRATE</div><div>84.8 GM</div></div><div className="d-flex justify-content-between px-4 "><div>SUGAR</div><div>41.1 GM</div></div><div className="d-flex justify-content-between px-4 "><div>PROTEIN</div><div>7.10 GM</div></div><div className="d-flex justify-content-between px-4 "><div>FATS</div><div>0.93 GM</div></div></div ><div className="d-flex bg-success bround text-white justify-content-between px-4 py-3"><div>ENERGY VALUE</div><div>376 KCAL</div></div></div >;
            ingridbox = <div className="d-flex flex-wrap nowrap">< div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw" ><h4 className="h-w-font m-0">Ragi</h4></div ><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Milk Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Elaichi Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Rolled Oats</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Shatavari Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Ashwagandha Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Sugar Barley</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Amaranth</h4></div></div >;
            break;

        case 'dhokla':
            productimg = './images/drragi-dhokla.webp';
            nutribox = <div className="nutribox">< div className="py-2" ><div className="d-flex justify-content-between px-4 pt-3"><div>CARBOHYDRATE</div><div>68.3 GM</div></div><div className="d-flex justify-content-between px-4 "><div>SUGAR</div><div>12.0 GM</div></div><div className="d-flex justify-content-between px-4 "><div>PROTEIN</div><div>13.50 GM</div></div><div className="d-flex justify-content-between px-4 "><div>Fats</div><div>01.38 GM</div></div></div ><div className="d-flex bg-success bround text-white justify-content-between px-4 py-3"><div>ENERGY VALUE</div><div>340 KCAL</div></div></div >;
            ingridbox = <div className="d-flex flex-wrap nowrap">< div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw" ><h4 className="h-w-font m-0">Ragi</h4></div ><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Green Moong</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Semolina Salt</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Salt</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Sugar</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Citric Acid</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Baking Soda</h4></div></div >;
            break;
        case 'pancake':
            productimg = './images/drragi-pancake.webp';
            nutribox = <div className="nutribox">< div className="py-2" ><div className="d-flex justify-content-between px-4 pt-3"><div>CARBOHYDRATE</div><div>82.7 GM</div></div><div className="d-flex justify-content-between px-4 "><div>SUGAR</div><div>30.9 GM</div></div><div className="d-flex justify-content-between px-4 "><div>PROTEIN</div><div>7.31 GM</div></div><div className="d-flex justify-content-between px-4 "><div>FATS</div><div>2.08 GM</div></div></div ><div className="d-flex bg-success bround text-white justify-content-between px-4 py-3"><div>ENERGY VALUE</div><div>375 KCAL</div></div></div >;
            ingridbox = <div className="d-flex flex-wrap nowrap">< div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw" ><h4 className="h-w-font m-0">Ragi</h4></div ><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Wheat</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Sugar</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Cocoa Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Salt</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Baking Powder</h4></div></div >;
            break;

        case 'tomato':
            productimg = './images/drragi-tomato.webp';
            nutribox = <div className="nutribox">< div className="py-2" ><div className="d-flex justify-content-between px-4 pt-3"><div>CARBOHYDRATE</div><div>75.8 GM</div></div><div className="d-flex justify-content-between px-4 "><div>PROTEIN</div><div>7.6 GM</div></div><div className="d-flex justify-content-between px-4 "><div>FATS</div><div>0.38 GM</div></div></div ><div className="d-flex bg-success bround text-white justify-content-between px-4 py-3"><div>ENERGY VALUE</div><div>303.6 KCAL</div></div></div >;
            ingridbox = <div className="d-flex flex-wrap nowrap">< div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw" ><h4 className="h-w-font m-0">Ragi</h4></div ><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Milk Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Tomato Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Rock Salt</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Garlic Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Ginger Powder</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Sugar</h4></div><div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw"><h4 className="h-w-font m-0">Black Pepper</h4></div></div >;
            break;

        case 'malt':
            productimg = './images/drragi-malt.webp';
            nutribox = <div className="nutribox">< div className="py-2" ><div className="d-flex justify-content-between px-4 pt-3"><div>CARBOHYDRATE</div><div>81.8 GM</div></div><div className="d-flex justify-content-between px-4 "><div>PROTEIN</div><div>6.20 GM</div></div><div className="d-flex justify-content-between px-4 "><div>FATS</div><div>0.18 GM</div></div></div ><div className="d-flex bg-success bround text-white justify-content-between px-4 py-3"><div>ENERGY VALUE</div><div>354 KCAL</div></div></div >;
            ingridbox = <div className="d-flex flex-wrap nowrap">< div className="bg-theme rounded-pill px-4 py-2 m-1 border border-3 border-white shadowinw" ><h4 className="h-w-font m-0">100 % Sprouted Ragi</h4></div ></div >;
            break;

        default:
            nutribox = 'N U L L';

            ingridbox = 'N U L L ';
            break;
    }
    return (
        <>
            <section className="section_padding bg-pro  position-relative" id="productsect">
                <div className="position-absolute d-none d-lg-block w-100 h-100 top-0">
                    <div className="position-sticky top-0">
                        <div className="d-flex justify-content-between">
                            <img alt="dr-ragi-vector" data-aos="zoom-in" data-aos-offset="300" src="./images/drragi-tl-boy.webp" width="10%" />
                            <img alt="dr-ragi-vector" data-aos="zoom-in" data-aos-offset="300" src="./images/drragi-tr-girl.webp" width="10%" />
                        </div>
                    </div>
                </div>
                <div className="position-absolute d-none d-lg-flex h-100 align-items-end w-100 bottom-0">
                    <div className="position-sticky w-100 bottom-0">
                        <div className="d-flex justify-content-between">
                            <img alt="dr-ragi-vector" data-aos="zoom-in" data-aos-offset="300" src="./images/drragi-bl-boy.webp" width="10%" />
                            <img alt="dr-ragi-vector" data-aos="zoom-in" data-aos-offset="300" src="./images/drragi-br-girl.webp" width="10%" />
                        </div>
                    </div>
                </div>
                <div className="container-lg container-fluid position-relativez-1">
                    <h2 className="text-center " id="productheading">Facts About Ragi</h2>
                    <div className="row animate__animated d-none d-lg-flex" id="productheading2">
                        <h2 className="display-5 col-md-4 text-end h-w-font text-white">Nutritious</h2>
                        <h2 className="display-5 offset-md-4 col-md-4 text-center h-w-font text-white">Ingridients</h2>
                    </div>
                    <div className="row productrow">
                        <div className="col-lg-4 col-md-6" id="facts-col1">
                            <div id="facts-box1" className="animate__animated mb-3 ms-3 ms-md-0">
                                <div className="d-flex align-items-center flex-row-reverse flex-md-row justify-content-end" data-aos="zoom-in-right" data-aos-offset="800">
                                    <div className="ps-3">
                                        <p className="facts-textl">Richest Source of Calcium</p>
                                    </div>
                                    <div>
                                        <div className="facts-icon-box border border-info border-4 bg-white mx-auto">
                                            <img alt="dr-ragi-bone" src="./images/drragi-bone.webp" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-row-reverse flex-md-row justify-content-end mt-2 mt-md-5" data-aos="zoom-in-right" data-aos-offset="1000">
                                    <div className="ps-3">
                                        <p className="facts-textl">Loaded with Natural Iron</p>
                                    </div>
                                    <div>
                                        <div className="facts-icon-box border border-info border-4 bg-white mx-auto">
                                            <img alt="dr-ragi-muscle" src="./images/drragi-muscule.webp" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-row-reverse flex-md-row justify-content-end mt-2 mt-md-5" data-aos="zoom-in-right" data-aos-offset="1300">
                                    <div className="ps-3">
                                        <p className="facts-textl">Antioxidant Rich</p>
                                    </div>
                                    <div>
                                        <div className="facts-icon-box border border-info border-4 bg-white mx-auto">
                                            <img alt="dr-ragi-antioxident" src="./images/drragi-antioxident.webp" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="animate__animated d-block d-md-none ms-3 ms-md-0">
                                <div className="d-flex align-items-center" data-aos="zoom-in-left" data-aos-offset="800">
                                    <div>
                                        <div className="facts-icon-box border border-info border-4 bg-white mx-auto">
                                            <img alt="dr-ragi-aminoacid" src="./images/drragi-aminoacid.webp" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <p className="facts-textr">Important Amino Acids</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-2 mt-md-5" data-aos="zoom-in-left" data-aos-offset="1000">
                                    <div>
                                        <div className="facts-icon-box border border-info border-4 bg-white mx-auto">
                                            <img alt="dr-ragi-gluten" src="./images/drragi-gluten.webp" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <p className="facts-textr">100% Gluten Free</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-2 mt-md-5" data-aos="zoom-in-left" data-aos-offset="1300">
                                    <div>
                                        <div className="facts-icon-box border border-info border-4 bg-white mx-auto">
                                            <img alt="dr-ragi-highfiber" src="./images/drragi-highfiber.webp" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <p className="facts-textr">High Fiber Content</p>
                                    </div>
                                </div>
                            </div>
                            <div id="nutrival" className=" animate__animated mb-5 text-dark">
                                {nutribox}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-none d-lg-block">
                            <div className="facts-product text-center">
                                <img alt="dr-ragi-product" src={productimg} className="w-90" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" id="facts-col2">
                            <div id="facts-box2" className="animate__animated d-none d-md-block">
                                <div className="d-flex align-items-center" data-aos="zoom-in-left" data-aos-offset="800">
                                    <div>
                                        <div className="facts-icon-box border border-info border-4 bg-white mx-auto">
                                            <img alt="dr-ragi-aminoacid" src="./images/drragi-aminoacid.webp" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <p className="facts-textr">Important Amino Acids</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-2 mt-md-5" data-aos="zoom-in-left" data-aos-offset="1000">
                                    <div>
                                        <div className="facts-icon-box border border-info border-4 bg-white mx-auto">
                                            <img alt="dr-ragi-gluten" src="./images/drragi-gluten.webp" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <p className="facts-textr">100% Gluten Free</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-2 mt-md-5" data-aos="zoom-in-left" data-aos-offset="1300">
                                    <div>
                                        <div className="facts-icon-box border border-info border-4 bg-white mx-auto">
                                            <img alt="dr-ragi-highfiber" src="./images/drragi-highfiber.webp" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <p className="facts-textr">High Fiber Content</p>
                                    </div>
                                </div>
                            </div>
                            <div id="ingridval" className="animate__animated mb-5">
                                {ingridbox}
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )

}

export default FactsProduct;