import React from "react";
import ProductHeroSection from "../components/ProductHeroSection";
import FactsProduct from "../components/FactsProduct";
import HowToCook from "../components/HowToCook";
import Testimonial from "../components/Testimonial";
import RelatedProduct from "../components/RelatedProduct";
function TomatoProduct() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Enjoy a Hot and Delicious Bowl of Tomato Soup in Minutes with Our Instant Premix';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Meta Description: Craving a comforting bowl of tomato soup? Our Instant Tomato Soup Premix has got you covered. Our delicious and easy-to-prepare soup mix is perfect for any time of day. Order now and savor the rich and savory taste of tomato soup in minutes.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);

    return (
        <>
            <ProductHeroSection productPage="tomato" />

            <section className="section_padding overflow-hidden bg-tomato">
                <div className="container">
                    <div className="row position-relative z-1 align-items-md-center align-items-lg-start">
                        <div className="position-absolute d-none d-lg-block top-0 z--1">
                            <img alt="dr-ragi-glow" src="./images/drragi-glow.webp" className="img-fluid mt-n1 rotate-circle" />
                        </div>
                        <div className="col-md-12 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <h2 className="display-5 h-w-font">Tomato Premix</h2>
                                <p>"Introducing our delectable Ragi Tomato Soup premix – a delightful blend of wholesome
                                    goodness and mouthwatering flavors that's perfect for everyone to savor! Whether
                                    you're young or young at heart, this nutritious and delicious soup will warm your soul
                                    and leave you craving for more. Embrace the taste of health and happiness in every
                                    comforting spoonful!"
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center">
                                <img alt="dr-ragi-tomato-premix" src="./images/drragi-tomato.webp" data-aos="fade-up" className="w-90 proimg" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <p className="text-white f-40">Get Nitritious Products at Home</p>
                                <p className="mt-4">Ratings : <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></p>
                                <h4 className="h-w-font my-3 nowrap d-flex">Social Media: <a href="https://www.facebook.com/DrRagiOfficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-facebook mx-2 f-30"></i></a><a href="https://www.youtube.com/channel/UCOPidVpLxybZ5_D530uezQA" target="_blank" className="text-white"> <i className="fa-brands fa-square-youtube mx-2 f-30"></i></a> <a href="https://www.instagram.com/drragiofficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-instagram mx-2 f-30"></i></a> </h4>
                                <div className="mt-5">
                                    <a target="_blank" href="https://www.amazon.in/l/27943762031?ie=UTF8&marketplaceID=A21TJRUUN4KGV&me=A235IMB28GQ4TO">
                                        <button className="buybtn w-100">Buy Now</button>
                                    </a>
                                </div>
                                <div className="d-flex gap-3 justify-content-center mt-5 flex-wrap" data-aos="zoom-in-right">
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-t-icon1.webp" width="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-t-icon2.webp" width="120px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-t-icon3.webp" height="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-t-icon4.webp" width="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-t-icon5.webp" width="70px" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FactsProduct productPage="tomato" />
            <HowToCook page="tomato" />
            <Testimonial />
            <RelatedProduct />

        </>
    )
}

export default TomatoProduct;