import React from "react";
import RecipeData from "../components/RecipeData";
import Blog from "../components/Blog";
import Subscribe from "../components/Subscribe";
import Testimonial from "../components/Testimonial";

function ChocolateRecipe() {

    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Indulge in the Rich and Healthy Flavor of Ragi Chocolate Drink';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Quench your thirst and indulge in the rich flavor of ragi and chocolate with our Ragi Chocolate Drink premix. This healthy and delicious drink is a perfect balance of taste and nutrition. Order now and enjoy the goodness in every sip.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);

    return (
        <>
            <div id="wrap">

                <RecipeData page="chocolate" />

                <Blog />

                <Subscribe />

                <Testimonial />
            </div>

        </>
    )
}

export default ChocolateRecipe;