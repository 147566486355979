import React from "react";
import ProductHeroSection from "../components/ProductHeroSection";
import FactsProduct from "../components/FactsProduct";
import HowToCook from "../components/HowToCook";
import Testimonial from "../components/Testimonial";
import RelatedProduct from "../components/RelatedProduct";
import { Link } from "react-router-dom";
function DhoklaProduct() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Meta Title: Buy Ragi Dhokla Premix Online | Healthy and Nutritious Snack Mix';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Meta Description: Enjoy a healthy and nutritious snack with our Ragi Dhokla Premix. Made with high-quality ingredients, this easy-to-make mix is perfect for those looking for a healthy snack option. Buy online now!';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);

    return (
        <>
            <ProductHeroSection productPage="dhokla" />

            <section className="section_padding overflow-hidden brown">
                <div className="container">
                    <div className="row position-relative z-1 align-items-md-center align-items-lg-start">
                        <div className="position-absolute d-none d-lg-block top-0 z--1">
                            <img alt="dr-ragi-glow" src="./images/drragi-glow.webp" className="img-fluid mt-n1 rotate-circle" />
                        </div>
                        <div className="col-md-12 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <h2 className="display-5 h-w-font">Dhokla Premix</h2>
                                <p>"Fuel your body with the best source of protein and essential minerals with our Ragi
                                    Dhokla premix! Not only does it make for a delicious snack and breakfast option, but it
                                    also provides a healthy boost of nutrients to support your overall well-being. Treat
                                    yourself to a nutritious delight that tastes as good as it feels!                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="flip-card mx-auto">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front ">
                                        <img alt="dr-ragi-dhokla-premix" src="./images/drragi-dhokla.webp" className="flipimg" />
                                    </div>
                                    <div className="flip-card-back ">
                                        <div className="product-image">
                                            <img alt="dr-ragi-dhokla-premix" src="./images/drragi-dhokla-back.webp" className="flipimg" />
                                        </div>
                                        <div className="zoomed-image-container">
                                            <div className="zoomed-image">
                                                <img alt="dr-ragi-dhokla-premix" src="./images/drragi-dhokla-back.webp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <p className="text-white f-40">Get Nitritious Products at Home</p>
                                <p className="mt-4">Ratings : <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></p>
                                <h4 className="h-w-font my-3 nowrap d-flex">Social Media: <a href="https://www.facebook.com/DrRagiOfficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-facebook mx-2 f-30"></i></a><a href="https://www.youtube.com/channel/UCOPidVpLxybZ5_D530uezQA" target="_blank" className="text-white"> <i className="fa-brands fa-square-youtube mx-2 f-30"></i></a> <a href="https://www.instagram.com/drragiofficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-instagram mx-2 f-30"></i></a> </h4>
                                <div className="mt-5">
                                    <a target="_blank" href="https://www.amazon.in/l/27943762031?ie=UTF8&marketplaceID=A21TJRUUN4KGV&me=A235IMB28GQ4TO">
                                        <button className="buybtn w-100">Buy Now</button>
                                    </a>
                                </div>
                                <div className="d-flex gap-3 justify-content-center mt-5 flex-wrap" data-aos="zoom-in-right">
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-d-icon.webp" width="80px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-d-icon2.webp" width="80px" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FactsProduct productPage="dhokla" />
            <HowToCook page="dhokla" />
            <Testimonial />
            <RelatedProduct />

        </>
    )
}

export default DhoklaProduct;