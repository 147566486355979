import React from "react";
function Privacy() {

    return (
        <>
            <section className="section_padding">
                <div className="container">
                    <p className="display-5 text-theme">Privacy Policy</p>
                    <p className="display-6">Our Privacy Policy was last updated on August 18, 2022.</p>
                    <p className="fs-5 lh-base">This Site is owned and operated by T K Foods, a Limited Liability Partnership registered under the under the Limited Liability Partnership Act, 2008 and having its principal place of business at Plot #148, D501 Swapnapoorti Tilaknagar East Mumbai 400089 (hereinafter referred to as "T K Foods”, "Site Owner", “we", "Our", or “us”).</p>
                    <br />
                    <p className="fs-5 lh-base">Any reference to "you" in this privacy policy ("Privacy Policy"), means “User”, “Client”, “Customer”, or “Your” for accessing, browsing, using the Site and for placing order for delivery listed on the Site ("User"). The products provided to the User for placing an order online displayed by the Site Owner on the Site, along with other services as may be included and modified from time to time, shall together be referred to as "Services".</p>
                    <br />
                    <p className="fs-5 lh-base">As a User, your access to and use of the Site is conditional upon your acceptance of and continued compliance with this Privacy Policy. By using the Site, you agree to be bound by this Privacy Policy and all rules, guidelines, policies including the Terms and Conditions as defined at site and applicable to the Services being provided by Site Owner ("Policies"), as may be modified by the Site Owner from time to time, unconditionally and at all times and further agree and confirm that you consent to providing the Site Owner with your personal information for the purpose set out below.</p>
                    <br />
                </div>

            </section>
        </>

    )
}

export default Privacy;