import React from "react";
function HowToCook(props) {
    let video_banner_recipe;
    let recipe;
    switch (props.page) {
        case 'chocolate':

            video_banner_recipe = <iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/F6hxSmmggwE" title="Dr Ragi Chocolate Drink Premix" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>;

            recipe = <> <div className="p-3 bg-primary rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01.In 100 ml of boiling milk add 1 - 2 TSP of Ragi Drink Premix</div ><div className="p-3 bg-theme  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">02. Stir well and cook on low flame for 2-3 min</div><div className="p-3 bg-danger  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Garnish with chocolate chips</div><div className="p-3 bg-warning  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04. Enjoy warm</div></>;
            break;

        case 'elaichi':
            video_banner_recipe = <iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/v_seZjAQZj8" title="Dr Ragi Elaichi Drink Premix" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen ></iframe >;

            recipe = <> <div className="p-3 bg-primary rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01.In 100 ml of boiling milk add 1 - 2 TSP of Ragi Drink Premix</div ><div className="p-3 bg-theme  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">02. Stir well and cook on low flame for 2-3 min</div><div className="p-3 bg-danger  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Garnish with dry fruits</div><div className="p-3 bg-warning  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04. Enjoy warm</div></>;
            break;

        case 'dhokla':
            video_banner_recipe = <iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/Wx069TNapkw" title="Dr Ragi Dhokla Premix" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen ></iframe >;

            recipe = <> <div className="p-3 bg-primary rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01.Add 2 TBSP of in 100 ml of water</div ><div className="p-3 bg-theme  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">02. Pour the mixture in 100 grams of ragi dhokla mix</div><div className="p-3 bg-danger  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Gently mix to form smooth batterwithout lumps</div><div className="p-3 bg-warning  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04. Grease the trey with little oil, pour the batter in it evenly</div><div className="p-3 bg-success  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">05. Stream for 15 - 20 min or till done</div><div className="p-3 bg-info  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">06. Remove and allow to cool for 10 min and cut into small cubes</div><div className="p-3 lightbrown  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">07.Temper with mustard seeds & curry leaves </div><div className="p-3 bg-warning  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">08. Garnish with coriander and grated cocnut.</div></>;
            break;

        case 'pancake':
            video_banner_recipe = <iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/pcyRX_4XH8Y" title="Dr Ragi PanCake Premix" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen ></iframe >;

            recipe = <> <div className="p-3 bg-primary rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01.Mix 1 / 4 cup of Ragi pancake mix with 1 / 4 cup of milk.</div ><div className="p-3 bg-theme  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">Heat a pan and pour the mix into the pan, do not spread it.</div><div className="p-3 bg-success  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Drizzle ghee or butter onto it cook one side well.</div><div className="p-3 bg-danger  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04. Flip to the other side and cook well.</div><div className="p-3 bg-warning  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">05. Serve with honey or dates syrup.</div></>;
            break;

        case 'tomato':
            video_banner_recipe = <iframe className="rounded-3 ytcook" src="https://www.youtube.com/embed/1PSsWyTU8cE" title="Dr Ragi Tomato Premix" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen ></iframe >;

            recipe = <> <div className="p-3 bg-primary rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">01.Mix 20 grams of ragi soup premix in 200 ml of water</div ><div className="p-3 bg-theme  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">02. Stir well till there are no lumps</div><div className="p-3 bg-success  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">03. Bring to boil, stirrng continuously</div><div className="p-3 bg-danger  rounded  fw-bold my-2" data-aos="fade-up" data-aos-duration="1000">04. Simmer for 2 - 3 minutes. Serve hot</div>'</>;
            break;

        default:
            video_banner_recipe = '';
            break;
    }
    return (
        <section id="howcook" className="py-5 position-relative bg-white">
            <div className="container">
                <div className="row">
                    <h2 className="text-center mb-5 fw-monospace" data-aos="fade-up">How to Cook</h2>
                    <div className="col-lg-8">
                        <div data-aos="zoom-in" data-aos-duration="1000">
                            {video_banner_recipe}
                        </div>
                    </div>
                    <div className="col-lg-4 text-white">
                        {recipe}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowToCook;