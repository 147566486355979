import { useEffect } from "react";
import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
function Research() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Get Nutritious and Delicious 100% Natural Ragi Products from Dr.Ragi';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Discover the goodness of 100% natural Ragi products from Dr.Ragi. Our bio-processed gluten-free products cater to your daily nutritional needs and taste buds. We aim to be your one-stop destination for superfoods and natural wellness products in India.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);
    useEffect(() => {
        AOS.init({
            // once: true,
            disable: function () {
                var maxWidth = 992;
                return window.innerWidth < maxWidth;
            }
        });
    }, [])

    return (
        <>
            <div id="wrap">
                <section className="">
                    <div className="wrapper">
                        <div className="block">
                            <div className="each-year">
                                <div className="title">Our Research & Observation</div>
                                <div className="resdiv">
                                    <div className="each-event" data-aos="zoom-in-right" data-aos-offset="50">
                                        <div className="event-description">
                                            <h4 className="text-center py-2 m-0 fw-bold text-white">2015</h4>
                                            <div className="card rounded-50">
                                                <img alt="dr-ragi-logo" src="./images/drragi-logo-2.webp" className="researchimg rounded-50" />
                                                <div className="card-body">
                                                    <div className="resicon ms-auto">
                                                        <img alt="dr-ragi-aminoacid" src="./images/drragi-aminoacid.webp" className="w-100" />
                                                    </div>
                                                    <h3 className="card-title">MSc Plant Biotechnology
                                                    </h3>
                                                    <p className="res-text">Come to know about finger millet (Ragi) and its various health benefits.
                                                        My curiosity about Ragi continued to grow, and I decided to pursue PhD in this field.
                                                        Awarded Late Dr. T. S. Mahabale Gold medal” for standing first in M.Sc. examination.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="each-event" data-aos="zoom-in-left">
                                        <div className="event-description">
                                            <h4 className="text-center py-2 m-0 fw-bold text-white">2016</h4>
                                            <div className="card rounded-50">
                                                <img alt="dr-ragi-logo" src="./images/drragi-logo-2.webp" className="researchimg rounded-50" />
                                                <div className="card-body">
                                                    <div className="resicon ms-auto">
                                                        <img alt="dr-ragi-aminoacid" src="./images/drragi-aminoacid.webp" className="w-100" />
                                                    </div>
                                                    <h3 className="card-title">PhD in Ragi</h3>
                                                    <p className="res-text">Collected 64 ragi landraces from different villages for analysing nutritional
                                                        composition of ragi and investigated the health benefits of consuming ragi.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="each-event" data-aos="zoom-in-right" data-aos-offset="50">
                                        <div className="event-description">
                                            <h4 className="text-center py-2 m-0 fw-bold text-white">2017</h4>
                                            <div className="card rounded-50">
                                                <img alt="dr-ragi-logo" src="./images/drragi-logo-2.webp" className="researchimg rounded-50" />
                                                <div className="card-body">
                                                    <div className="resicon ms-auto">
                                                        <img alt="dr-ragi-aminoacid" src="./images/drragi-aminoacid.webp" className="w-100" />
                                                    </div>
                                                    <h3 className="card-title">Bioprocessing</h3>
                                                    <p className="res-text">Standardized the malting process to make ragi more nutritious.
                                                        In the case of ragi, the malting process can help in increasing its nutritional value by making the nutrients more bioavailable. However, the malting process needs to be standardized to achieve consistent results.
                                                        .</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="each-event" data-aos="zoom-in-left">
                                        <div className="event-description">
                                            <h4 className="text-center py-2 m-0 fw-bold text-white">2018</h4>
                                            <div className="card rounded-50">
                                                <img alt="dr-ragi-logo" src="./images/drragi-logo-2.webp" className="researchimg rounded-50" />
                                                <div className="card-body">
                                                    <div className="resicon ms-auto">
                                                        <img alt="dr-ragi-aminoacid" src="./images/drragi-aminoacid.webp" className="w-100" />
                                                    </div>
                                                    <h3 className="card-title">Publication of research papers in different national and international journals</h3>
                                                    <p className="res-text">These papers have covered various aspects of your research, such as the nutritional composition of different ragi landraces, the health benefits of consuming ragi, the effects of the malting process on ragi, and the development of ragi-based products.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="each-event" data-aos="zoom-in-right" data-aos-offset="50">
                                        <div className="event-description">
                                            <h4 className="text-center py-2 m-0 fw-bold text-white">2019</h4>
                                            <div className="card rounded-50">
                                                <img alt="dr-ragi-logo" src="./images/drragi-logo-2.webp" className="researchimg rounded-50" />
                                                <div className="card-body">
                                                    <div className="resicon ms-auto">
                                                        <img alt="dr-ragi-aminoacid" src="./images/drragi-aminoacid.webp" className="w-100" />
                                                    </div>
                                                    <h3 className="card-title">Value addition</h3>
                                                    <p className="res-text">Made different recipes out of processed ragi malt with natural ingredients.
                                                        have developed a range of ragi-based recipes that are not only nutritious but also tasty and appealing to consumers.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="each-event" data-aos="zoom-in-left">
                                        <div className="event-description">
                                            <h4 className="text-center py-2 m-0 fw-bold text-white">2020</h4>
                                            <div className="card rounded-50">
                                                <img alt="dr-ragi-logo" src="./images/drragi-logo-2.webp" className="researchimg rounded-50" />
                                                <div className="card-body">
                                                    <div className="resicon ms-auto">
                                                        <img alt="dr-ragi-aminoacid" src="./images/drragi-aminoacid.webp" className="w-100" />
                                                    </div>
                                                    <h3 className="card-title">Product development</h3>
                                                    <p className="res-text">Best quality Ragi-based products , made from 100% natural ingredients, free from any preservatives or chemicals and suitable for all age group.Value addition through recipe development can help to promote the consumption of ragi
                                                        and increase its marketability, as consumers are more likely to buy products that are not
                                                        only nutritious but also delicious and easy to prepare</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="bg-pro">
                    <div className="container py-5  text-white">
                        <p>Value addition through recipe development can help to promote the consumption of ragi and increase its marketability, as consumers are more likely to buy products that are not only nutritious but also delicious and easy to prepare.
                            <blockquote>

                                After years of extensive research and hard work, I am proud to present to you the best source of antioxidants, the super grain Ragi. Our Ragi-based products are bio-processed and packed with essential nutrients to ensure that you get the best of both worlds - taste and nutrition.
                            </blockquote>
                        </p>
                    </div>
                </section>
            </div>
        </>

    )
}

export default Research;