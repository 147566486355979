import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';

function Header() {
    const handleClick = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    const location = useLocation();
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);

    useEffect(() => {
        if (location.pathname === '/dr-ragi-chocolate-drink-premix' || location.pathname === '/dr-ragi-elaichi-drink-premix' || location.pathname === '/dr-ragi-dhokla-premix' || location.pathname === '/dr-ragi-pancake-premix' || location.pathname === '/dr-ragi-tomato-premix' || location.pathname === '/dr-ragi-malt-premix') {

        }
        else {
            const handleScroll = () => {
                if (window.scrollY > 0) {
                    setIsHeaderFixed(false);
                } else {
                    setIsHeaderFixed(false);
                }
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [location.pathname]);

    const headerClass = isHeaderFixed ? 'header fixed-header' : 'header';
    return (
        <>
            <header className={`bg-header ${headerClass} `}>
                <div className="header-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-xl-6">
                                <div className="header-top-info">
                                    <a href="whatsapp://send?phone=+918208636171">
                                        <i className="ri ri-whatsapp-line"></i>
                                        +91 820 863 6171
                                    </a>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 text-end d-xl-block d-none">
                                <div className="header-top-info">
                                    <a href="mailto:hello@drragi.in">
                                        <i className="ri ri-mail-line"></i>
                                        hello@drragi.in
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-bottom">
                    <div className="container">
                        <div className="desktop-header d-xl-block d-none">
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <div className="header-nav-wrap">
                                        <nav className="navbar navbar-expand-xl p-0">
                                            <div className="collapse ok navbar-collapse" id="navbarSupportedContent">
                                                <div className="navbar-nav mx-auto align-items-center">
                                                    <ul className="nav-left p-0">
                                                        <li className="nav-item">
                                                            <NavLink className="nav-link" aria-current="page" onClick={handleClick} to="/">Home</NavLink>
                                                        </li>
                                                        <li className="nav-item">
                                                            <NavLink className="nav-link" aria-current="page" onClick={handleClick} to="about-us">About
                                                                Us</NavLink>
                                                        </li>
                                                        <li className="nav-item dropdown">
                                                            <div className="nav-link curser-pointer dropdown-toggle">
                                                                Products
                                                            </div>
                                                            <ul className="dropdown-menu">
                                                                <li><NavLink className="dropdown-item " onClick={handleClick} to="dr-ragi-chocolate-drink-premix">Ragi Chocolate Drink Premix</NavLink></li>
                                                                <li><NavLink className="dropdown-item " onClick={handleClick} to="dr-ragi-elaichi-drink-premix">Ragi Elaichi Drink Premix</NavLink></li>
                                                                <li><NavLink className="dropdown-item " onClick={handleClick} to="dr-ragi-pancake-premix">Ragi Pancake Premix</NavLink></li>
                                                                <li><NavLink className="dropdown-item " onClick={handleClick} to="dr-ragi-tomato-premix">Ragi Tomato Soup Premix</NavLink></li>
                                                                <li><NavLink className="dropdown-item " onClick={handleClick} to="dr-ragi-dhokla-premix">Ragi Dhokla Premix</NavLink></li>
                                                                <li><NavLink className="dropdown-item " onClick={handleClick} to="dr-ragi-malt-premix">Ragi Ragi Malt</NavLink></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <div className="nav-center d-none d-xl-block">
                                                        <NavLink className="navbar-brand m-0 p-0 " onClick={handleClick} to="/"><img alt="dr-ragi-logo" src="./images/drragi-logo.webp" className="img-fluid logoh" /></NavLink>
                                                    </div>
                                                    <ul className="nav-right p-0">
                                                        <li className="nav-item">
                                                            <NavLink className="nav-link " onClick={handleClick} to="research">
                                                                Research
                                                            </NavLink>
                                                        </li>
                                                        <li className="nav-item dropdown">
                                                            <div className="nav-link  curser-pointer dropdown-toggle" >
                                                                Recipes
                                                            </div>
                                                            <ul className="dropdown-menu">
                                                                <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-chocolate-drink-premix-recipe">Ragi Chocolate Drink Premix</NavLink></li>
                                                                <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-elaichi-drink-premix-recipe">Ragi Elaichi Drink Premix</NavLink></li>
                                                                <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-pancake-premix-recipe">Ragi Pancake Premix</NavLink></li>
                                                                <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-tomato-premix-recipe">Ragi Tomato Soup Premix</NavLink></li>
                                                                <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-dhokla-premix-recipe">Ragi Dhokla Premix</NavLink></li>
                                                            </ul>
                                                        </li>
                                                        <li className="nav-item">
                                                            <NavLink className="nav-link" onClick={handleClick} to="contact-us">Contact Us</NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mobile-menu-wrap d-xl-none d-block">
                            <div className="mobile-header">
                                <div className="mb-header-left">
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedmobile" aria-controls="navbarSupportedmobile" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"><i className="ri-menu-4-fill"></i></span>
                                    </button>
                                    <nav className="navbar navbar-expand-xl p-0">
                                        <div className="collapse navbar-collapse" id="navbarSupportedmobile">
                                            <span className="close-menu"><i className="ri-close-line"></i></span>
                                            <div className="text-center pt-5 pb-0">
                                                <NavLink onClick={handleClick} to="/">
                                                    <img alt="dr-ragi-logo" src="./images/drragi-logo.webp" className="img-fluid logoh" />
                                                </NavLink>
                                            </div>
                                            <ul className="navbar-nav mx-auto align-items-center">
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" aria-current=" page" onClick={handleClick} to="/" role="button">Home</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" aria-current="page" onClick={handleClick} to="about-us" role="button">About Us</NavLink>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <div className="nav-link curser-pointer dropdown-toggle" onClick={handleClick} to="" id="menuDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Products
                                                    </div>
                                                    <ul className="dropdown-menu">
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-chocolate-drink-premix">Ragi Chocolate
                                                            Premix</NavLink></li>
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-elaichi-drink-premix">Ragi Elaichi Premix</NavLink></li>
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-dhokla-premix">Ragi Dhokla Premix</NavLink></li>
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-pancake-premix">Ragi Pancake Premix</NavLink></li>
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-tomato-premix">Ragi Tomato Soup Premix</NavLink>
                                                        </li>
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-malt-premix">Ragi Malt Premix</NavLink></li>
                                                    </ul>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" aria-current="page" onClick={handleClick} to="/research" role="button">Research</NavLink>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <div className="nav-link curser-pointer dropdown-toggle" onClick={handleClick} to="" data-bs-toggle="dropdown">
                                                        Recipes
                                                    </div>
                                                    <ul className="dropdown-menu">
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-chocolate-drink-premix-recipe">Ragi Chocolate Drink Premix</NavLink></li>
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-elaichi-drink-premix-recipe">Ragi Elaichi Drink Premix</NavLink></li>
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-pancake-premix-recipe">Ragi Pancake Premix</NavLink></li>
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-tomato-premix-recipe">Ragi Tomato Soup Premix</NavLink></li>
                                                        <li><NavLink className="dropdown-item" onClick={handleClick} to="dr-ragi-dhokla-premix-recipe">Ragi Dhokla Premix</NavLink></li>
                                                    </ul>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" onClick={handleClick} to="contact-us">Contact Us</NavLink>
                                                </li>
                                            </ul>

                                            <div className="conatct-info-header">
                                                <h2 className="text-white">Contact Us</h2>
                                                <ul className="d-flex p-0 pb-4">
                                                    <li className="m-0 me-3"><a onClick={handleClick} to="https://www.facebook.com/" target="_blank" className="s-icons"><i className="ri-facebook-fill"></i></a></li>
                                                    <li className="m-0 me-3"><a onClick={handleClick} to="https://twitter.com/login" className="s-icons" target="_blank"><i className="ri-twitter-fill"></i></a></li>
                                                    <li className="m-0 me-3"><a onClick={handleClick} to="https://www.instagram.com/" className="s-icons" target="_blank"><i className="ri-instagram-line"></i></a></li>
                                                    <li className="m-0 me-3"><a onClick={handleClick} to="https://www.linkedin.com/signup" className="s-icons" target="_blank"><i className="ri-whatsapp-line"></i></a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </nav>
                                </div>
                                <div className="mb-header-center">
                                    <div className="nav-center py-2">
                                        <NavLink className="navbar-brand m-0 p-0" onClick={handleClick} to="/">
                                            <img alt="dr-ragi-logo" src="./images/drragi-logo.webp" className="img-fluid logoh" />
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="mb-header-right">
                                    <div className="header-top-info">
                                        <a onClick={handleClick} to="mailto:contact@lucha.com"><i className="ri-mail-unread-line"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </header >
        </>
    )
}

export default Header;