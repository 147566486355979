import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Counter from "../components/Counter";
import Service from "../components/Service";
import Blog from "../components/Blog";
import Testimonial from "../components/Testimonial";
import Subscribe from "../components/Subscribe";
import { Modal, Button } from 'react-bootstrap';

function Home() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Get Nutritious and Delicious 100% Natural Ragi Products from Dr.Ragi';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Discover the goodness of 100% natural Ragi products from Dr.Ragi. Our bio-processed gluten-free products cater to your daily nutritional needs and taste buds. We aim to be your one-stop destination for superfoods and natural wellness products in India.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);
    const navigate = useNavigate();

    function dhoklalink() {
        navigate("/dr-ragi-dhokla-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function pancakelink() {
        navigate("/dr-ragi-pancake-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function chocolatelink() {
        navigate("/dr-ragi-chocolate-drink-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function maltlink() {
        navigate("/dr-ragi-malt-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function tomatolink() {
        navigate("/dr-ragi-tomato-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    function elaichilink() {
        navigate("/dr-ragi-elaichi-drink-premix", { replace: true });;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        });
    }
    useEffect(() => {
        const menu2 = document.getElementById('menu2');
        const menu3 = document.getElementById('menu3');
        const menu = document.getElementById('menu');

        function handleScroll() {
            if (window.scrollY > menu2.offsetTop - 350) {
                menu.dispatchEvent(new CustomEvent('changeColor', { detail: { color: '#cc6369' } }));

                if (window.scrollY > menu3.offsetTop - 350) {
                    menu.dispatchEvent(new CustomEvent('changeColor', { detail: { color: '#5571f2' } }));
                }
            } else {
                menu.dispatchEvent(new CustomEvent('changeColor', { detail: { color: '#27b652' } }));
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleColorChange(event) {
        const menu = document.getElementById('menu');
        menu.style.backgroundColor = event.detail.color;
    }

    useEffect(() => {
        const menu = document.getElementById('menu');
        menu.addEventListener('changeColor', handleColorChange);

        return () => {
            menu.removeEventListener('changeColor', handleColorChange);
        };
    }, []);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div id="wrap">
                <section className="hero-slider">
                    <div className="pagetitle-bg">
                        <div className="big-banner home-bg">
                        </div>
                    </div>
                    <div className="container page-title home-about-title">
                        <h3 className="hometitle text-white">
                            <span className="left_line"></span>
                            <span className="flex-fill">Research Based Food Products
                            </span>
                            <span className="right_line"></span>
                        </h3>
                    </div>
                </section>

                <section className="section_padding">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-12 col-lg-4">
                                <div className="about_image position-relative p-sm-5">
                                    <img alt="dr-ragi-banner" src="./images/about-main.jpg" className="img-fluid " data-aos="fade-down" data-aos-duration="1000" />
                                    <div className="video-play ">
                                        <a role="button" className="video_button" onClick={handleShow}>
                                            <div className="button-outer-circle has-scale-animation"></div>
                                            <div className="button-outer-circle has-scale-animation has-delay-short"></div>
                                            <i className="ri-play-line"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-8 mt-mobile featured_blog">
                                <div className="small_heading mb-1 about-heading">
                                    <h6>Presenting unmatched research food products.</h6><span className="right_line"></span>
                                </div>
                                <h2 className="display-6 mb-30 text-theme menuh" style={{ opacity: 1, fontSize: '3rem', lineHeight: '60px', }}>"Introducing Unmatched Research-Based Food Products,
                                    Crafted with Over 6 Years of Rigorous PhD Research!"</h2>
                                <p>The journey from being a Ragi Girl to Dr. Ragi is a fascinating one. Ragi is a super grain that is loaded
                                    with nutrients, and it has been an essential part of the Indian diet for centuries.</p>
                                <p>Dr. Tahsin Kazi's passion for Ragi started during her PHD research. Dr. Kazi focused on Ragi and
                                    conducted extensive research on its nutritional properties. She learned about the various ways in which
                                    Ragi can be processed and how it can be used to create a wide range of food products.</p>
                            </div>
                            <ul className="about-service row">
                                <li className="d-flex align-items-center  col-md-6">
                                    <img alt="dr-ragi-vission" src="./images/drragi-vision.png" className="abouticons p-2 overflow-visible border-theme rounded-circle" />
                                    <div className="extra-about">
                                        <h3>Our Vision</h3>
                                        <p className="mb-0">To be a one-stop destination to buy superfoods and natural wellness
                                            products in India.</p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-center  col-md-6">
                                    <img alt="dr-ragi-mission" src="./images/drragi-mission.png" className="abouticons p-2 overflow-visible border-theme rounded-circle" />
                                    <div className="extra-about">
                                        <h3>Our Mission</h3>
                                        <p className=" mb-0">Maintaining the highest standards in sourcing, packaging and
                                            distribution of our products.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <Counter color='#27b652'></Counter>

                <section className="bgmenu" id="menu">
                    <section className="py-5 position-relative">

                        <div className="container-fluid p-sm-5" id="menu1">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6">
                                    <div className="section_heading mb-0 text-center newsletter-heading">
                                        <h2 className="mb-0 text-white menuh">Drink Premix</h2>
                                        <hr className="my-3 h border w-75 mx-auto border-white border-3" />
                                        <h3 className="text-white mt-4">Freshen Your Cup With Hints Of Nutrients. </h3>
                                        <h3 className="text-white ">Quick and Easy to make !!! </h3>
                                    </div>
                                </div>
                                <h4 className="d-none">Hidden</h4>
                                <h5 className="d-none">Hidden</h5>
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-6 col-sm-4 col-lg-5" data-aos="fade-left" data-aos-duration="500">
                                            <div onClick={chocolatelink}>
                                                <img alt="dr-ragi-chocolate-premix" src="./images/drragi-chocolate.webp" className="w-100 menu-img" />
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-4 col-lg-5" data-aos="fade-left" data-aos-duration="1000">
                                            <div onClick={elaichilink}>
                                                <img alt="dr-ragi-elaichi-premix" src="./images/drragi-elaichi.webp" className="w-100 menu-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 position-relative" id="menu2">

                        <div className="container-fluid p-sm-5">
                            <div className="row flex-row-reverse align-items-center">
                                <div className="col-12 col-lg-6">
                                    <div className="section_heading mb-0 text-center newsletter-heading">
                                        <h2 className="mb-0 text-white menuh">Breakfast Premix</h2>
                                        <hr className="my-3 h border w-75 mx-auto border-white border-3" />
                                        <h3 className="text-white mt-4">The perfect way to start your day with a healthy and nutritious meal. A unique and tasty
                                            alternative for breakfast.
                                        </h3>
                                    </div>
                                </div>
                                <h4 className="d-none">Hidden</h4>
                                <h5 className="d-none">Hidden</h5>
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-6 col-sm-5 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                                            <div onClick={dhoklalink}>
                                                <img alt="dr-ragi-dhokla-premix" src="./images/drragi-dhokla.webp" className="w-100 menu-img " />
                                            </div>
                                        </div>
                                        <div className="col-5 col-sm-4 col-lg-5" data-aos="fade-right" data-aos-duration="500">
                                            <div onClick={pancakelink}>
                                                <img alt="dr-ragi-pancake-premix" src="./images/drragi-pancake.webp" className="w-100 menu-img " />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 position-relative" id="menu3">

                        <div className="container-fluid p-sm-5">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6">
                                    <div className="section_heading mb-0 text-center newsletter-heading">
                                        <h2 className="mb-0 text-white menuh">Soup and Beverage premix</h2>
                                        <hr className="my-3 h border w-75 mx-auto border-white border-3" />
                                        <h3 className="text-white mt-4">Packed with a host of nutrients.Wholesome Goodness in Every Bowl!</h3>
                                    </div>
                                </div>
                                <h4 className="d-none">Hidden</h4>
                                <h5 className="d-none">Hidden</h5>
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-5 col-sm-4 col-lg-5" data-aos="fade-left" data-aos-duration="500">
                                            <div onClick={tomatolink}>
                                                <img alt="dr-ragi-tomato-premix" src="./images/drragi-tomato.webp" className="w-100 menu-img" />
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-5 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                                            <div onClick={maltlink}>
                                                <img alt="dr-ragi-malt-premix" src="./images/drragi-malt.webp" className="w-100 menu-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <Service></Service>

                <Blog></Blog>

                <Testimonial></Testimonial>

                <Subscribe></Subscribe>

            </div >
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className='vh-80'>
                    <video autoPlay src="./images/broucher-final-video-for-yt.mp4" className='w-100 h-100' controls></video>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Home;