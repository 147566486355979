import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';


function Footer() {
    useEffect(() => {
        const handleScroll = () => {
            const scrollOffset = window.pageYOffset;
            const documentHeight = document.documentElement.scrollHeight - window.innerHeight;
            const path = document.querySelector(".scroll-top path");
            const pathLength = path.getTotalLength();
            path.style.strokeDashoffset = pathLength - (scrollOffset * pathLength) / documentHeight;

            if (scrollOffset > 50) {
                document.querySelector(".scroll-top").classList.add("active");
            } else {
                document.querySelector(".scroll-top").classList.remove("active");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleButtonClick = () => {
        window.scrollTo({ top: 0, behavior: "instant" });
    };

    return (
        <>


            <footer className="bg-light pt-4 fot">
                <div className="container">
                    <div className="row">
                        <div className="col-12">

                            <div className="row pb-5 g-5 align-items-center justify-content-between">
                                <div className="col-lg-4 d-flex flex-column text-center justify-content-center align-items-center">
                                    <img alt="dr-ragi-logo" src="./images/drragi-logo.webp" width="300px" className="img-fluid" />
                                    <div className="mt-sm-4">After years of extensive research and hard work, I am proud to present to you the best source of antioxidants, the super grain Ragi. Our Ragi-based products are bio-processed and packed with essential nutrients to ensure that you get the best of both worlds - taste and nutrition.</div>
                                </div>
                                <div className="col-md-3 col-lg-2  d-flex justify-content-center" style={{ marginBottom: '30px', }}>
                                    <ul className="list-unstyled d-grid gap-2 ">
                                        <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/">Home</NavLink>
                                        </li>
                                        <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/about-us">About us</NavLink>
                                        </li>
                                        {/* <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/blog">Blog</NavLink>
                                        </li> */}
                                        <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/contact-us">Contact us</NavLink>
                                        </li>
                                        <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/research">Research</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3 col-lg-2  d-flex justify-content-center">
                                    <ul className="list-unstyled d-grid gap-2 ">
                                        <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/cookies">Cookies Policy</NavLink>
                                        </li>
                                        <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/privacy">Privacy Policy</NavLink>
                                        </li>
                                        <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/refund">Refund Policy</NavLink>
                                        </li>
                                        <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/terms">Terms & Conditions</NavLink>
                                        </li>
                                        <li className="my-1">
                                            <NavLink onClick={handleButtonClick} className="letter-spacing" to="/shipping">Shipping Policy</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-lg-4 d-grid gap-4 flex-column d-flex justify-content-center align-items-center">
                                    <div className="text-center d-flex my-4 flex-wrap gap-3 justify-content-center align-items-center">
                                        <img alt="dr-ragi-fssai" src="./images/drragi-fssai-logo.png" width="70px" height="40px" className="me-3" />
                                        <h5 className="m-0 ">Registration No : 11521027001133</h5>
                                    </div>
                                    <div className="conatct-detail m-0 nowrap">
                                        <i className="text-theme bi bi-phone "></i><span><a href="whatsapp://send?phone=+918208636171" className="">+91 820
                                            863 6171</a></span>
                                    </div>
                                    <div className=" conatct-detail m-0 nowrap">
                                        <i className="text-theme ri-mail-unread-line"></i><span><a href="mailto:hello@drragi.in" className="">hello@drragi.in</a></span>
                                    </div>
                                    <ul className="d-flex p-0">
                                        <li className="m-0 me-3"><a href="https://www.facebook.com/DrRagiOfficial/" target="_blank" className="s-icons f-icons"><i className="ri-facebook-fill"></i></a></li>
                                        <li className="m-0 me-3"><a href="https://www.youtube.com/channel/UCOPidVpLxybZ5_D530uezQA" className="s-icons f-icons" target="_blank"><i className="ri-youtube-fill"></i></a></li>
                                        <li className="m-0 me-3"><a href="https://www.instagram.com/drragiofficial/" className="s-icons f-icons" target="_blank"><i className="ri-instagram-line"></i></a></li>
                                        <li className="m-0 me-3"><a href="whatsapp://send?phone=+918208636171" className="s-icons f-icons" target="_blank"><i className="ri-whatsapp-line"></i></a></li>
                                        <li className="m-0 me-3"><a href="https://twitter.com/DrRagiOfficial" className="s-icons f-icons" target="_blank"><i className="ri-twitter-line"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3 border-top border-1 border-secondary text-center ">
                    <p className="m-0">Copyright &copy; T K Foods. All rights reserved</p>
                </div>
            </footer>

            <div onClick={handleButtonClick} className="scroll-top" data-scroll="up">
                <svg className="border-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d={`M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98`} style={{ strokeDashoffset: "0" }}></path>
                </svg>
            </div>

            <a href="whatsapp://send?phone=+918208636171">
                <svg viewBox="0 0 32 32" className="whatsapp-ico">
                    <path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fillRule="evenodd"></path>
                </svg></a>
        </>

    )
}

export default Footer;