import React from "react";
import ProductHeroSection from "../components/ProductHeroSection";
import FactsProduct from "../components/FactsProduct";
import HowToCook from "../components/HowToCook";
import Testimonial from "../components/Testimonial";
import RelatedProduct from "../components/RelatedProduct";
function PancakeProduct() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Whip Up Delicious Pancakes in Minutes with Our Instant Pancake Premix';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Make breakfast a breeze with our Instant Pancake Premix. Our pancake mix is easy to prepare and makes fluffy, mouth-watering pancakes in minutes. Order now and enjoy a delicious breakfast any day of the week.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);

    return (
        <>
            <ProductHeroSection productPage="pancake" />

            <section className="section_padding overflow-hidden bg-pancake">
                <div className="container">
                    <div className="row position-relative z-1 align-items-md-center align-items-lg-start">
                        <div className="position-absolute d-none d-lg-block top-0 z--1">
                            <img alt="dr-ragi-glow" src="./images/drragi-glow.webp" className="img-fluid mt-n1 rotate-circle" />
                        </div>
                        <div className="col-md-12 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <h2 className="display-5 h-w-font">Pancake Premix</h2>
                                <p>Specially formulated complete mix that makes soft, light and fluffy egg-less
                                    pancakes
                                    Filled with nutrients which has several benefits.
                                    Ragi malt as a major ingredient which will fulfil your need of micronutrients like calcium and
                                    iron.
                                    Ragi pancakes are the ultimate choice for kids' tiffin and snacks, boasting delicious
                                    flavors and wholesome nutrition to fuel their day with a smile!"                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center">
                                <img alt="dr-ragi-pancake-premix" src="./images/drragi-pancake.webp" data-aos="fade-up" className="w-90 proimg" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <p className="text-white f-40">Get Nitritious Products at Home</p>
                                <p className="mt-4">Ratings : <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></p>
                                <h4 className="h-w-font my-3 nowrap d-flex">Social Media: <a href="https://www.facebook.com/DrRagiOfficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-facebook mx-2 f-30"></i></a><a href="https://www.youtube.com/channel/UCOPidVpLxybZ5_D530uezQA" target="_blank" className="text-white"> <i className="fa-brands fa-square-youtube mx-2 f-30"></i></a> <a href="https://www.instagram.com/drragiofficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-instagram mx-2 f-30"></i></a> </h4>
                                <div className="mt-5">
                                    <a href="https://www.amazon.in/dp/B0CCDSV716?&_encoding=UTF8&tag=drragi-21&linkCode=ur2&linkId=7b411ba88d10e048f626049e8bbf9a06&camp=3638&creative=24630">
                                        <button className="buybtn w-100">Buy Now</button>
                                    </a>
                                </div>
                                <div className="d-flex gap-3 justify-content-center mt-5 flex-wrap" data-aos="zoom-in-right">
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-p-icon1.webp" width="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-p-icon2.webp" width="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-p-icon3.webp" height="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-p-icon4.webp" width="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/drragi-p-icon5.webp" width="70px" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FactsProduct productPage="pancake" />
            <HowToCook page="pancake" />
            <Testimonial />
            <RelatedProduct />

        </>
    )
}

export default PancakeProduct;