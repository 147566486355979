import React from "react";
import { Link } from "react-router-dom";

function ProductHeroSection(props) {
    let bgimage;
    let productlocation;
    let productname;
    let productcolor;
    let productcolorname;

    switch (props.productPage) {
        case 'chocolate':
            bgimage = 'url(./images/drragi-chocolate-product-banner.webp)';
            productlocation = 'Chocolate Premix';
            productname = 'Ragi Chocolate Premix';
            productcolor = 'brown';
            productcolorname = ' text-theme';
            break;
        case 'elaichi':
            bgimage = 'url(./images/drragi-elaichi-product-banner.webp)';
            productlocation = 'Elaichi Premix';
            productname = 'Ragi Elaichi Premix';
            productcolor = 'bg-elaichi';
            productcolorname = ' text-success';
            break;
        case 'dhokla':
            bgimage = 'url(./images/drragi-dhokla-product-banner.webp)';
            productlocation = 'Dhokla Premix';
            productname = 'Ragi Dhokla Premix';
            productcolor = 'brown';
            productcolorname = ' text-theme';
            break;
        case 'pancake':
            bgimage = 'url(./images/drragi-pancake-product-banner.webp)';
            productlocation = 'Pancake Premix';
            productname = 'Ragi Pancake Premix';
            productcolor = 'bg-pancake';
            productcolorname = ' text-theme';
            break;
        case 'tomato':
            bgimage = 'url(./images/drragi-tomato-product-banner.webp)';
            productlocation = 'Tomato Soup Premix';
            productname = 'Ragi Tomato Soup Premix';
            productcolor = 'bg-tomato';
            productcolorname = ' text-theme';
            break;
        case 'malt':
            bgimage = 'url(./images/drragi-malt-product-banner.webp)';
            productlocation = 'Malt Premix';
            productname = 'Ragi Malt Premix';
            productcolor = 'bg-success';
            productcolorname = 'text-success ';
            break;
        case 'recipe':
            bgimage = 'url(./images/drragi-about-banner.webp)';
            productlocation = 'Recipe';
            productname = 'Ragi ';
            productcolor = '';
            productcolorname = ' text-theme';
            break;

        default:
            bgimage = 'url(./images/drragi-about-banner.webp)';
            productlocation = 'home';
            productname = 'home';
            productcolor = 'bg-success';
            productcolorname = ' text-theme';
            break;
    }
    const myStyle = {
        background: bgimage
    };
    return (
        <>
            <section className="hero-slider">
                <div className="pagetitle-bg">
                    <div className="big-banner bg-product"
                        style={myStyle}></div>
                </div>
                <div className="container page-title">
                    <div className="product-t">
                        <h1 className={` display-4 ${productcolorname}`}>{productname}</h1>
                    </div>
                </div>
            </section >
        </>
    )
}

export default ProductHeroSection;