import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Blog() {
    const settings = {
        slidesToShow: 2,
        centerPadding: '100px',
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '30px',
                },
            },
            {
                breakpoint: 991.5,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1
                },
            },
        ],
    };

    return (
        <section className="section_padding blog_section pt-0">
            <div className="container-fluid position-relative blog-section home-blogs">
                <div className="blogbg"></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fill-opacity="1" d="M0,160L60,165.3C120,171,240,181,360,186.7C480,192,600,192,720,181.3C840,171,960,149,1080,170.7C1200,192,1320,256,1380,288L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
                <div className="container">
                    <div className="section_heading text-center mb-0">
                        <h2 className="main_heading mb-30 text-white">Signature Premix Recipes</h2>
                        <p className="text-white">Our signature recipes are vegan, gluten-free, and free from any artificial preservatives, making it a perfect option.</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="blog_slider_section" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                    <div className="blog-slider-swipe">
                        <Slider className={`swiper-wrapper `} {...settings}>
                            <div className="swiper-slide">
                                <div className="blog-item mt-0">
                                    <div className="blog-image h-250">
                                        <span className="hover-shine"></span>
                                        <img alt="dr-ragi-chocolate-premix" src="./images/drragi-chocolate-banner.webp" className="w-100 h-100 object-fit" />
                                    </div>

                                    <h3 className="blog-title layout-2-title"><Link to="dr-ragi-chocolate-drink-premix-reciepe" className="text-black">Dr Ragi - Chocolate Drink Premix Recipe</Link></h3>
                                    <p className="mb-0 blog-list-desc">Ragi chocolate drink premix is a mixture of ragi, cocoa powder and other ingredients that can be used to make a chocolate-flavored drink.</p>
                                    <Link to="dr-ragi-chocolate-drink-premix-reciepe" className="button_with_text">Watch Video<svg width="28" height="16"
                                        viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M24.172 7.00017L18.808 1.63617L20.222 0.222168L28 8.00017L20.222 15.7782L18.808 14.3642L24.172 9.00017H0V7.00017H24.172Z"
                                            fill="#616F7D" />
                                    </svg></Link>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="blog-item mt-0">
                                    <div className="blog-image h-250">
                                        <span className="hover-shine"></span>
                                        <img alt="dr-ragi-pancake-premix" src="./images/drragi-pancake-banner.webp" className="w-100 h-100 object-fit" />
                                    </div>

                                    <h3 className="blog-title layout-2-title"><Link to="dr-ragi-pancake-premix-reciepe" className="text-black">Dr Ragi - PanCake Premix Recipe</Link></h3>
                                    <p className="mb-0 blog-list-desc">Ragi pancake is a nutritious breakfast or snack made using ragi/finger millet/nagli.</p>
                                    <Link to="dr-ragi-pancake-premix-reciepe" className="button_with_text">Watch Video<svg width="28" height="16"
                                        viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M24.172 7.00017L18.808 1.63617L20.222 0.222168L28 8.00017L20.222 15.7782L18.808 14.3642L24.172 9.00017H0V7.00017H24.172Z"
                                            fill="#616F7D" />
                                    </svg></Link>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="blog-item mt-0">
                                    <div className="blog-image h-250">
                                        <span className="hover-shine"></span>
                                        <img alt="dr-ragi-dhokla-premix" src="./images/drragi-dhokla-banner.webp" className="w-100 h-100 object-fit" />
                                    </div>

                                    <h3 className="blog-title layout-2-title"><Link to="dr-ragi-dhokla-premix-reciepe" className="text-black">Dr Ragi - Dhokla Premix Recipe</Link></h3>
                                    <p className="mb-0 blog-list-desc">Ragi dhokla is a great snack option for those looking for a healthy and gluten-free alternative to traditional snacks.</p>
                                    <Link to="dr-ragi-dhokla-premix-reciepe" className="button_with_text">Watch Video<svg width="28" height="16"
                                        viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M24.172 7.00017L18.808 1.63617L20.222 0.222168L28 8.00017L20.222 15.7782L18.808 14.3642L24.172 9.00017H0V7.00017H24.172Z"
                                            fill="#616F7D" />
                                    </svg></Link>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="blog-item mt-0">
                                    <div className="blog-image h-250">
                                        <span className="hover-shine"></span>
                                        <img alt="dr-ragi-tomato-premix" src="./images/drragi-tomato-banner.webp" className="w-100 h-100 object-fit" />
                                    </div>

                                    <h3 className="blog-title layout-2-title"><Link to="dr-ragi-tomato-premix-recipe" className="text-black">Dr Ragi - Tomato Soup Premix Recipe</Link></h3>
                                    <p className="mb-0 blog-list-desc">Ragi tomato soup premix is a mixture of ragi/finger millet/nagli,  tomato powder, and other seasonings that can be used to make a nutritious and flavourful soup. </p>
                                    <Link to="dr-ragi-tomato-premix-recipe" className="button_with_text">Watch Video<svg width="28" height="16"
                                        viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M24.172 7.00017L18.808 1.63617L20.222 0.222168L28 8.00017L20.222 15.7782L18.808 14.3642L24.172 9.00017H0V7.00017H24.172Z"
                                            fill="#616F7D" />
                                    </svg></Link>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="blog-item mt-0">
                                    <div className="blog-image h-250">
                                        <span className="hover-shine"></span>
                                        <img alt="dr-ragi-elaichi-premix" src="./images/drragi-elaichi-banner.webp" className="w-100 h-100 object-fit" />
                                    </div>

                                    <h3 className="blog-title layout-2-title"><Link to="dr-ragi-elaichi-drink-premix-reciepe" className="text-black">Dr Ragi - Elaichi Drink Premix Recipe</Link></h3>
                                    <p className="mb-0 blog-list-desc">Ragi elaichi drink premix is a mixture of ragi, cardamom powder and other ingredients that can be used to make a elaichi-flavored drink. </p>
                                    <Link to="dr-ragi-elaichi-drink-premix-reciepe" className="button_with_text">Watch Video<svg width="28" height="16"
                                        viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M24.172 7.00017L18.808 1.63617L20.222 0.222168L28 8.00017L20.222 15.7782L18.808 14.3642L24.172 9.00017H0V7.00017H24.172Z"
                                            fill="#616F7D" />
                                    </svg></Link>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Blog;