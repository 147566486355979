import React, { useEffect } from "react";
import ProductHeroSection from "../components/ProductHeroSection";
import FactsProduct from "../components/FactsProduct";
import HowToCook from "../components/HowToCook";
import Testimonial from "../components/Testimonial";
import RelatedProduct from "../components/RelatedProduct";
function ChocolateProduct() {

    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Indulge in the Rich and Healthy Flavor of Ragi Chocolate Drink';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Quench your thirst and indulge in the rich flavor of ragi and chocolate with our Ragi Chocolate Drink premix. This healthy and delicious drink is a perfect balance of taste and nutrition. Order now and enjoy the goodness in every sip.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);

    return (
        <>
            <ProductHeroSection productPage="chocolate" />

            <section className="section_padding overflow-hidden brown">
                <div className="container">
                    <div className="row position-relative z-1 align-items-md-center align-items-lg-start">
                        <div className="position-absolute d-none d-lg-block top-0 z--1">
                            <img alt="dr-ragi-glow" src="./images/drragi-glow.webp" className="img-fluid mt-n1 rotate-circle" />
                        </div>
                        <div className="col-md-12 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <h2 className="display-5 h-w-font">Chocolate Premix</h2>
                                <p>Give your kids a delightful and healthy treat with our yummy Ragi drink premix in
                                    delicious chocolate flavor! It's packed with goodness that will make them grow strong
                                    and stay happy. Make healthy choices and treat them to a tasty and nourishing drink
                                    they'll love!"                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="text-center">
                                <img alt="dr-ragi-chocolate-premix" src="./images/drragi-chocolate.webp" data-aos="fade-up" className="w-90 proimg" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4" data-aos="fade-down">
                            <div className="text-white">
                                <p className="text-white f-40">Get Nitritious Products at Home</p>
                                <p className="mt-4">Ratings : <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></p>
                                <h4 className="h-w-font my-3 nowrap d-flex">Social Media: <a href="https://www.facebook.com/DrRagiOfficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-facebook mx-2 f-30"></i></a><a href="https://www.youtube.com/channel/UCOPidVpLxybZ5_D530uezQA" target="_blank" className="text-white"> <i className="fa-brands fa-square-youtube mx-2 f-30"></i></a> <a href="https://www.instagram.com/drragiofficial/" target="_blank" className="text-white"><i className="fa-brands fa-square-instagram mx-2 f-30"></i></a> </h4>
                                <div className="mt-5">
                                    <a href="https://www.amazon.in/dp/B0CBS8THL6?&_encoding=UTF8&tag=drragi-21&linkCode=ur2&linkId=35a92e88bfa576bbad6e382eead44f8d&camp=3638&creative=24630">
                                        <button className="buybtn w-100">Buy Now</button>
                                    </a>
                                </div>
                                <div className="d-flex gap-3 justify-content-center mt-5 flex-wrap" data-aos="zoom-in-right">
                                    <div><img alt="dr-ragi-icon" src="./images/c1.webp" width="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/c3.webp" height="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/c4.webp" width="70px" /></div>
                                    <div><img alt="dr-ragi-icon" src="./images/c2.webp" width="120px" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FactsProduct productPage="chocolate" />
            <HowToCook page="chocolate" />
            <Testimonial />
            <RelatedProduct />

        </>
    )
}

export default ChocolateProduct;