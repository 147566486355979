import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect, useLayoutEffect } from 'react';
// import { ScrollToTop } from 'react-scroll-to-top';
import $ from 'jquery';
import Home from "./pages/Home";
import Header from "./components/Header"
import Footer from "./components/Footer";
import AboutUs from './pages/AboutUs';
import Research from "./pages/Research";
import ContactUs from "./pages/ContactUs";
import ChocolateProduct from "./pages/ChocolateProduct";
import ElaichiProduct from "./pages/ElaichiProduct";
import DhoklaProduct from "./pages/DhoklaProduct";
import PancakeProduct from "./pages/PancakeProduct";
import TomatoProduct from "./pages/TomatoProduct";
import MaltProduct from "./pages/MaltProduct";
import ChocolateRecipe from "./pages/ChocolateRecipe";
import ElaichiRecipe from "./pages/ElaichiRecipe";
import DhoklaRecipe from "./pages/DhoklaRecipe";
import PancakeRecipe from './pages/PancakeRecipe';
import TomatoRecipe from './pages/TomatoRecipe';
import Terms from "./pages/Terms";
import Cookies from "./pages/Cookies";
import Privacy from "./pages/Privacy";
import Refund from "./pages/Refund";
import Shipping from "./pages/Shipping";
import AOS from 'aos';
import './assets/css/all.min.css';
import "./assets/css/bootstrap.min.css"
import "./assets/css/swiper.min.css"
import './assets/css/photoswipe.css';
import './assets/css/default-skin.css';
import 'aos/dist/aos.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/custom.css';
import './assets/css/single-dish.css';
// import './assets/js/index.js'
// import './assets/js/style.js'
import 'aos/dist/aos.js';
// function ScrollToTopOnMount() {
//   const location = useLocation();

//   useLayoutEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location.pathname]);

//   return null;
// }
function App() {
  // const location = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);
  useEffect(() => {

    AOS.init({
      once: true,
    });

    AOS.refresh();
    $(document).on("click", function (event) {
      if ($(event.target).closest(".navbar-toggler").length) {
        $(".navbar-collapse.collapse.show").toggle();
      } else if (!$(event.target).closest(".navbar-collapse.collapse.show").length) {
        $(".navbar-collapse").removeClass('collapse show');

      }
    });


    $('.close-menu').on("click", function () {
      $('.navbar-collapse').removeClass('collapse show')
    });
  }, []);

  return (
    <>
      <Router>
        <Header />
        {/* <ScrollToTopOnMount /> */}
        {/* <ScrollToTop /> */}
        <Routes>
          <Route index exact path="/" element={<Home />} />
          <Route exact path='/about-us' element={<AboutUs />} />
          <Route exact path='/dr-ragi-chocolate-drink-premix' element={<ChocolateProduct />} />
          <Route exact path='/dr-ragi-elaichi-drink-premix' element={<ElaichiProduct />} />
          <Route exact path='/dr-ragi-dhokla-premix' element={<DhoklaProduct />} />
          <Route exact path='/dr-ragi-pancake-premix' element={<PancakeProduct />} />
          <Route exact path='/dr-ragi-tomato-premix' element={<TomatoProduct />} />
          <Route exact path='/dr-ragi-malt-premix' element={<MaltProduct />} />
          <Route exact path='/dr-ragi-chocolate-drink-premix-recipe' element={<ChocolateRecipe />} />
          <Route exact path='/dr-ragi-elaichi-drink-premix-recipe' element={<ElaichiRecipe />} />
          <Route exact path='/dr-ragi-dhokla-premix-recipe' element={<DhoklaRecipe />} />
          <Route exact path='/dr-ragi-pancake-premix-recipe' element={<PancakeRecipe />} />
          <Route exact path='/dr-ragi-tomato-premix-recipe' element={<TomatoRecipe />} />
          <Route exact path='/research' element={<Research />} />
          <Route exact path='/contact-us' element={<ContactUs />} />
          <Route exact path='/terms' element={<Terms />} />
          <Route exact path='/cookies' element={<Cookies />} />
          <Route exact path='/privacy' element={<Privacy />} />
          <Route exact path='/shipping' element={<Shipping />} />
          <Route exact path='/refund' element={<Refund />} />
        </Routes>
        <Footer />
      </Router>
    </>

  );
}

export default App;
