import React from "react";
import CountUp, { useCountUp } from 'react-countup';

function Counter({ color }) {
    useCountUp({
        ref: 'counter',
        end: 1234567,
        enableScrollSpy: true,
        scrollSpyDelay: 1000,
    });
    return (

        <section className="position-relative counter-section">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill={color} fill-opacity="1" d="M0,160L60,165.3C120,171,240,181,360,186.7C480,192,600,192,720,181.3C840,171,960,149,1080,170.7C1200,192,1320,256,1380,288L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                        <div className="counter text-center" data-aos="flip-up" data-aos-easing="linear"
                            data-aos-duration="1000">
                            <h2 className="c_item"><CountUp start={0}
                                end={25}
                                duration={2.75} enableScrollSpy />+</h2>
                            <p className="c_desc mb-0">Authentic Products</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                        <div className="counter text-center" data-aos="flip-up" data-aos-easing="linear"
                            data-aos-duration="1000">
                            <h2 className="c_item"><CountUp start={0}
                                end={6}
                                duration={2.75} enableScrollSpy />+</h2>
                            <p className="c_desc mb-0">Years of PhD Research</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                        <div className="counter text-center" data-aos="flip-up" data-aos-easing="linear"
                            data-aos-duration="1000">
                            <h2 className="c_item"><CountUp start={0}
                                end={100}
                                duration={2.75} enableScrollSpy />+</h2>
                            <p className="c_desc mb-0">Happy Customers</p>
                        </div>
                    </div>
                </div>
                <h4 className="d-none">Hidden</h4>
                <h5 className="d-none">Hidden</h5>
            </div>
        </section>
    )
}

export default Counter;