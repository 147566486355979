import React from "react";
import RecipeData from "../components/RecipeData";
import Blog from "../components/Blog";
import Subscribe from "../components/Subscribe";
import Testimonial from "../components/Testimonial";

function TomatoRecipe() {
    let metaTitle = document.getElementById('metatitle');
    let metaKey = document.getElementById('metakey');
    let metaDesc = document.getElementById('metadesc');

    let titleValue = 'Enjoy a Hot and Delicious Bowl of Tomato Soup in Minutes with Our Instant Premix';
    let keyValue = '100% Natural Ragi Products, Finger Millet Products, Gluten-free Products, Healthy Food Products';
    let descValue = 'Meta Description: Craving a comforting bowl of tomato soup? Our Instant Tomato Soup Premix has got you covered. Our delicious and easy-to-prepare soup mix is perfect for any time of day. Order now and savor the rich and savory taste of tomato soup in minutes.';

    metaTitle.innerText = titleValue;
    metaKey.setAttribute('content', keyValue);
    metaDesc.setAttribute('content', descValue);

    return (
        <>
            <div id="wrap">

                <RecipeData page="tomato" />

                <Blog />

                <Subscribe />

                <Testimonial />
            </div>

        </>
    )
}

export default TomatoRecipe;