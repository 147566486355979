import React from "react";
function Subscribe() {
    return (
        <>
            <section className="py-5 bgragi position-relative subscribe">
                <div className="container">
                    <div className="row align-items-center" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
                        <div className="col-12 col-md-6 get-connect-border">
                            <div className="section_heading text-center mb-0 newsletter-heading">
                                <a href="whatsapp://send?phone=+918208636171" target="_blank">
                                    <img alt="dr-ragi-whatsapp" className="hover-sub img-fluid" src="./images/drragi-whatsappimg.webp" width="300px" />
                                </a>
                                <h2 className="main_heading mb-0 text-white">Get in touch</h2>
                                <p className="text-white my-3">We would love to hear from you!</p>
                                <div className="">
                                </div>
                            </div>
                        </div>
                        <h4 className="d-none">Hidden</h4>
                        <h5 className="d-none">Hidden</h5>
                        <div className="col-12 col-md-6 align-self-baseline	">
                            <div className="section_heading text-center mb-0 newsletter-heading">
                                <a target="_blank" href="https://www.amazon.in/l/27943762031?ie=UTF8&marketplaceID=A21TJRUUN4KGV&me=A235IMB28GQ4TO">
                                    <img alt="dr-ragi-amazon" className="hover-sub img-fluid" src="./images/drragi-amazonimg.webp" width="300px" />
                                </a>
                                <h2 className="main_heading mb-0 text-white">Home Delivery</h2>
                                <p className="text-white my-3">Make a Happy Shopping!</p>
                                <div className="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Subscribe;