import React from "react";
function Terms() {

    return (
        <>
            <section className="section_padding">
                <div className="container">
                    <p className="display-6 text-theme">Product Description</p>
                    <p className="fs-5 lh-base">Our ragi products are made from high-quality ragi grains, carefully selected and processed to ensure maximum nutritional value and taste. All products are research based </p>
                </div>
                <div className="container">
                    <p className="display-6 text-theme">
                        Nutritional Information
                    </p>
                    <p className="fs-5 lh-base">
                        We provide accurate and up-to-date nutritional information for our ragi product.However, please note that nutritional values may vary slightly depending on factors such as serving size and preparation method.
                    </p>
                </div>
                <div className="container">
                    <p className="display-6 text-theme">
                        Quality Assurance
                    </p>
                    <p className="fs-5 lh-base">
                        We take pride in maintaining the highest standards of quality for our ragi product. It undergoes rigorous testing and quality control measures at every stage of production to ensure freshness, purity, and consistency.
                    </p>
                </div>
                <div className="container">
                    <p className="display-6 text-theme">
                        Intellectual Property Rights
                    </p>
                    <p className="fs-5 lh-base">
                        All content, including text, images, graphics, logos, and product information, is the property of our company and protected by intellectual property laws. Unauthorized use or reproduction of any content is strictly prohibited.
                    </p>
                </div>
                <div className="container">
                    <p className="display-6 text-theme">
                        Product Quality and Storage
                    </p>
                    <p className="fs-5 lh-base">
                        Our ragi products are carefully prepared and packaged to maintain freshness and quality. It is recommended to store them in a cool, dry place away from direct sunlight. Please refer to the product packaging for specific storage instructions and best-before dates.
                    </p>
                </div>
            </section>
        </>

    )
}

export default Terms;